import React from 'react';
import { Route } from 'react-router-dom';
import Sidebar from '@components/Sidebar';
import toolbar from './toolbar';
import page from './page';

export default {
  toolbar: [
    <Route key="toolbar" exact path="/dashboard/playground" component={toolbar} />
  ],

  sidebar: [
    <Route key="sidebar" exact path="/dashboard/playground" component={() => <Sidebar defaultMenu />} />
  ],

  routes: [
    <Route key="routes" exact path="/dashboard/playground" component={page} />
  ],
};
