import home from './home';
import types from './types';
import entries from './entries';
import assets from './assets';
import playground from './playground';

const pages = [
  home,
  types,
  entries,
  assets,
  playground,
];

const toolbar = pages.map((p) => p.toolbar).filter(p => p);
const sidebar = pages.map((p) => p.sidebar).filter(p => p);
const routes = pages.map((p) => p.routes).filter(p => p);

export default {
  toolbar,
  sidebar,
  routes,
};
