import React, { useEffect, useRef } from 'react';
import { useSetState } from '@utils/hooks';
import { EditorContainer } from './styles';
// import codemirror from 'codemirror';
// import 'codemirror/mode/gfm/gfm';
// import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/ssms.css';

const Editor = ({
  output,
  onChange,
}) => {
  const editorEl = useRef();

  const [state, setState] = useSetState({});

  useEffect(() => {
    const { selectionStart, selectionEnd } = state;
    if (selectionStart && selectionEnd) {
      editorEl.current.setSelectionRange(selectionStart, selectionEnd);
    }
  }, [output]);

  const onChangeHandler = (e) => {
    const { value, selectionStart, selectionEnd } = e.target;

    setState(state => {
      state.selectionStart = selectionStart;
      state.selectionEnd = selectionEnd;
    });

    onChange(value);
  }

  return (
    <EditorContainer>
      <textarea ref={editorEl} value={output} onChange={onChangeHandler} />
    </EditorContainer>
  );
}

export default Editor;
