import _ from 'lodash';
import immer from 'immer';
import { generateUID } from '@utils/helpers';
import { contentTypes } from '@utils/constants';


export const findPath = (obj, path) => {
  for (var key in obj) {
    if (obj[key] && typeof obj[key] === "object") {
      var result = findPath(obj[key], path);
      if (result) {
        result.unshift(key);
        return result;
      }
    } else if (key === 'id' && obj[key] === path) {
      return [key];
    }
  }
}

export const getByPath = (obj, path, separator = '.') => {
  var properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const reorderByPath = (obj, path, startIndex, endIndex) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      if (acc[key]) {
        acc[key] = reorder(
          acc[key],
          startIndex,
          endIndex,
        );
      }
      return true;
    }
    return acc[key];
  }, _obj);

  return _obj;
}

export const deleteByPath = (obj, path) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc.splice(key, 1);
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

export const duplicateByPath = (obj, path, newItem) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc.subItems.splice(index + 1, 0, Object.assign(Object.assign({}, newItem)));
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

export const updateByPath = (obj, path, props) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = { ...acc[key], ...props };
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

const addByPath = (obj, path, draggedItem, destinationIndex) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key].subItems.splice(destinationIndex, 0, draggedItem);
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getSortedData = (result, contentTypeDraft) => {
  const sourceDroppableId = result.source.droppableId;
  const destinationDroppableId = result.destination.droppableId;
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  if (result.type === "section") {
    log("Reorder sections");

    const draft = immer(contentTypeDraft, draftState => {
      draftState.data = reorder(
        draftState.data,
        sourceIndex,
        destinationIndex
      );

      return draftState;
    });

    return draft;
  } else if (
    !result.draggableId.startsWith('csType') &&
    sourceDroppableId === destinationDroppableId
  ) {
    log("Reorder Items");

    const destinationPath = findPath(contentTypeDraft.data, result.draggableId);
    destinationPath.pop();
    destinationPath.pop();

    const draft = immer(contentTypeDraft, draftState => {
      draftState.data = reorderByPath(draftState.data, destinationPath, sourceIndex, destinationIndex);

      return draftState;
    });

    return draft;
  } else if (
    !result.draggableId.startsWith('csType') &&
    sourceDroppableId !== destinationDroppableId
  ) {
    log("Transfer Items");

    const { draggableId } = result;

    const sourcePath = findPath(contentTypeDraft.data, draggableId);
    sourcePath.pop();

    const draggedItem = getByPath(contentTypeDraft.data, sourcePath);


    const tempDraft = immer(contentTypeDraft, draftState => {
      draftState.data = deleteByPath(draftState.data, sourcePath);

      return draftState;
    });

    const destinationPath = findPath(tempDraft.data, destinationDroppableId);
    destinationPath.pop();

    // const target = _.get(contentTypeDraft.data, destinationPath);

    // if (
    //   target.subItems.some(x => x.id === draggableId)
    // ) {
    //   log("This one already exist");
    //   return contentTypeDraft;
    // }

    const draft = immer(tempDraft, draftState => {
      draftState.data = addByPath(draftState.data, destinationPath, draggedItem, destinationIndex);

      return draftState;
    });

    return draft;
  } else if (
    result.draggableId.startsWith('csType')
  ) {
    log("Add Item from sidebar");

    const destinationPath = findPath(contentTypeDraft.data, destinationDroppableId);
    destinationPath.pop();

    const draft = immer(contentTypeDraft, draftState => {
      const uid = generateUID(8);
      const draggedItem = {
        ...contentTypes[sourceIndex],
        id: uid, // override the id
        apiId: uid, // override the apiId
      };

      draftState.data = addByPath(draftState.data, destinationPath, draggedItem, destinationIndex);

      return draftState;
    });

    return draft;
  }

  return contentTypeDraft;
};
