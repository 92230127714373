import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import immer from 'immer';
import { withContext } from '@context';
import Icon from '@components/Icon';
import { Container, Title, CollapseIcon } from './styles';

const SectionHeading = ({
  sectionIndex,
  dispatch,
  contentEntry: {
    draft: contentEntryDraft,
  },
}) => {
  const data = contentEntryDraft.data[sectionIndex];
  const { isOpen, isNew } = data.__meta;
  const isDisabled = data.subItems.length === 0;

  const init = {
    data,
    isOpen,
    isNew,
    isDisabled,
  };

  const [state, setState] = useState(init);
  const [initialState, setInitialState] = useState();
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const data = contentEntryDraft.data[sectionIndex];
    const { isOpen, isNew } = contentEntryDraft.data[sectionIndex].__meta;
    const isDisabled = data.subItems.length === 0;

    const init = {
      data,
      isOpen,
      isNew,
      isDisabled,
    };

    setInitialState(init);
    setState(init);
  }, [contentEntryDraft]);

  const handleCancel = () => {
    setShowSettings(false);
    setState(initialState);
  }

  const toggleSectionHandler = () => {
    const draft = immer(contentEntryDraft, draftState => {
      draftState.data[sectionIndex].__meta.isOpen = !state.isOpen;
    });

    dispatch({
      type: 'CONTENT_ENTRY_UPDATE',
      payload: {
        draft
      }
    });
  }

  const sectionName = state.data.label || <span>Section Name ...</span>;

  return (
    <Container>
      <Title>
        <CollapseIcon onClick={toggleSectionHandler} isOpen={state.isOpen} isDisabled={state.isDisabled}>
          <Icon name="icon-arrow-right" fill="var(--color-cs-fill-grey-3)" size={[8]} />
        </CollapseIcon>
        <div onClick={() => setShowSettings(true)}>
          {sectionName}
        </div>
      </Title>
    </Container>
  )
};

export default withContext(['contentEntry'])(SectionHeading);
