export const contentSections = [
  {
    id: null,
    apiId: "",
    type: "section",
    label: "",
    subItems: [],
    __meta: {
      required: ["id", "label"],
      isNew: true,
      isOpen: true,
    },
  }
];

export const contentTypes = [
  {
    id: null,
    apiId: "",
    type: "title",
    label: "Title",
    settings: [
      {
        label: 'placeholder',
        type: 'placeholder',
        value: "",
      },
      // {
      //   label: 'Number of Characters min',
      //   type: 'minmax',
      //   value: "",
      // },
      // {
      //   label: 'Validation (Regex)',
      //   type: 'validationRegex',
      //   value: "",
      // },
      {
        label: 'required',
        type: 'required',
        value: false,
      },
    ],
    __meta: {
      isNew: true,
      required: ['id', 'label'],
      label: "Title",
      desc: "A heading field",
      icon: "icon-types-title",
    }
  },
  {
    id: null,
    apiId: "",
    type: "richText",
    label: "Rich Text",
    settings: [
      {
        label: 'placeholder',
        type: 'placeholder',
        value: "",
      },
      {
        label: 'required',
        type: 'required',
        value: false,
      }
    ],
    __meta: {
      isNew: true,
      required: ['id', 'label'],
      label: "Rich Text",
      desc: "A rich text field",
      icon: "icon-types-markdown"
    }
  },
  {
    id: null,
    apiId: "",
    type: "group",
    label: "Group",
    subItems: [],
    settings: [
      {
        label: 'repeatable',
        type: 'repeatable',
        value: false,
      }
    ],
    __meta: {
      isNew: true,
      isOpen: true,
      required: ['id', 'label'],
      label: "Group",
      desc: "A repeatable group of fields",
      icon: "icon-types-group"
    }
  },
  {
    id: null,
    apiId: "",
    type: "globalField",
    label: "Global Field",
    subItems: [],
    settings: [
      {
        label: 'repeatable',
        type: 'repeatable',
        value: false,
      },
    ],
    __meta: {
      isNew: true,
      isOpen: true,
      required: ['id', 'label'],
      label: "Global Field",
      desc: "A repeatable Global Field",
      icon: "icon-types-globalField"
    }
  },
];
