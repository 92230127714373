import React, { useState } from "react";
import { useInterval } from '@utils/hooks';
import moment from 'moment';

const RenderDate = ({ date }) => {
  const [reRenderCount, setReRenderCount] = useState(0);

  useInterval(() => {
    setReRenderCount(reRenderCount + 1);
  }, 180000); // 3 min.


  return moment(date).fromNow();
}

export default RenderDate;
