import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;

  input {
    display: flex;
    width: calc(100% - 40px);
    margin-left: auto;
    height: 54px;
    padding: 0;
    color: #273142;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid rgba(0,0,0, .1);
    background: #fff;
    transition: all .3s linear;
    -webkit-appearance: none;

    &:focus {
      border-bottom: solid 1px #ededed;
      outline: 0;
      box-shadow: 0 2px 6px -8px #ededed;
    }

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder,
    ::placeholder {
      color: #273142;
      font-size: 16px;
      font-weight: 400;
      background: transparent;
      outline: none;
    }

    &:not(:placeholder-shown) {
      padding: 24px 0 10px;

      + label {
        transform: translateY(-10px);
        opacity: .4;
      }
    }

    &:not(:valid):not(:focus) + label {
      color: red;

      + .icon {
        svg {
          fill: red !important;

          ${() => css`
            animation: ${shake} .3s;
          `}
        }
      }
    }
  }

  label {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    padding-left: 40px;
    font-size: 12px;
    opacity: 0;
    transition: all .3s ease;
    pointer-events: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    transform: translateY(-50%);

    svg {
      transition: all .3s ease;
    }
  }
`;

const displacement = '4px'
const shake = keyframes`
  0% { transform: translateX(-${displacement});}
  20% { transform: translateX(${displacement});}
  40% { transform: translateX(-${displacement});}
  60% { transform: translateX(${displacement});}
  80% { transform: translateX(-${displacement});}
  100% { transform: translateX(0);}
`;
