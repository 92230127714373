import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { withContext } from '@context';
import { useCancellablePromise } from '@utils/hooks';
import API from '@api';
import Create from './create';
import { Toolbar, ContentHolder, Content } from './styles';
import Grid from '@components/Grid';

const List = ({
  dispatch,
  history,
  match,
}) => {

  const { params } = match;
  const isGlobalField = params.type === 'global-fields';
  const refContainer = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [dimensions, setDimensions] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, [params]);

  useLayoutEffect(() => {
    const { current } = refContainer;

    if (current) {
      setDimensions({
        width: current.offsetWidth,
        height: current.offsetHeight
      });

      setIsLoading(false);
    }
  }, []);


  const onRowClick = (row) => {
    history.push(`/dashboard/${params.type}/${row.objectId}`)
  }

  const columns = ['name', 'itemsCount', 'updatedAt'];

  const { cancellablePromise } = useCancellablePromise();
  const fetchData = async ({ afterDate } = {}) => {
    try {
      const call = API.getModels({ afterDate, limit: 60, isGlobalField });

      const data = await cancellablePromise(call);

      const { total, list } = data;
      const picked = list.map(o => (({ objectId, name, itemsCount, updatedAt }) => ({ objectId, name, itemsCount, updatedAt }))(o.draft));

      return {
        total,
        list: picked,
      }
    } catch (e) {
      console.error(e.message);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: e.message,
      });
    }
  };

  return (
    <>
      <Toolbar>
        <span />
        <Create label={`New ${isGlobalField ? 'Global Field' : 'Content Type'}`} />
      </Toolbar>
      <ContentHolder>
        <Content ref={refContainer}>
          {
            (dimensions && !isLoading) && (
              <Grid
                dimensions={dimensions}
                fetchData={fetchData}
                columns={columns}
                onRowClick={onRowClick}
                gridHeaderItems={['Name', 'Entries', 'Last Update']}
                gridTemplateColumns="1fr 160px 160px"
              />
            )
          }
        </Content>
      </ContentHolder>
    </>
  );
}

export default withContext(['snackbar'])(List);
