import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 64px;
  min-width: 64px;
  border-right: 1px solid #EAEDF3;
  background-color: #fff;
  transition: all .2s;
  overflow: hidden;

  ${({ expand }) => expand && `
    width: 242px;
    min-width: 242px;
  `}
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`;
