import _ from 'lodash';

const initialState = {
  total: 0,
  list: null,
}

const contentEntriesSet = (state, payload) => {
  return { ...state, ...payload }
};

const contentEntriesAdd = (state, payload) => {
  const existingIndex = state.list.findIndex(f => f.draft.objectId === payload.draft.objectId);

  if (existingIndex !== -1) {
    state.list[existingIndex] = payload;
  } else {
    state.list.push(payload);
  }
  return { ...state };
};

const effects = {
  CONTENT_ENTRIES_SET: contentEntriesSet,
  CONTENT_ENTRIES_ADD: contentEntriesAdd,
};

export default {
  initialState,
  effects
};
