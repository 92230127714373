const initialState = [];

const environmentSet = (state, payload) => {
  return [...payload];
};

const effects = {
  ENVIRONMENTS_SET: environmentSet,
};

export default {
  initialState,
  effects
};
