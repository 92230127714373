import React from "react";
import ReactDOM from "react-dom";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Item from './components/items';
import { Section, Holder } from './styles';

const portal = document.createElement("div");
document.body.appendChild(portal);

const List = ({
  provided: pProvided,
  snapshot: pSnapshot,
  item,
  isDragStart,
  isDropDisabledForGroups,
}) => {
  const isSectionOpen = item.__meta.isOpen;
  const usePortal = pSnapshot.isDragging;

  const child = (
    <Droppable
      droppableId={item.id}
      isDropDisabled={!isSectionOpen}
    >
      {(provided, dSnapshot) => {

        return (
          <Section.Content
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDragStart={isSectionOpen && isDragStart}
            isSectionOpen={isSectionOpen}
            isDragging={pSnapshot.isDragging}
            isDraggingOver={dSnapshot.isDraggingOver}
          >
            {
              item.subItems.map((subItem, subItemIndex) => {
                return (
                  <Draggable
                    key={subItem.id}
                    draggableId={subItem.id}
                    index={subItemIndex}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Holder
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Item
                            item={subItem}
                            isSectionOpen={isSectionOpen}
                            dragHandleProps={provided.dragHandleProps}
                            isDragStart={isDragStart}
                            isDropDisabledForGroups={isDropDisabledForGroups}

                            isDragging={snapshot.isDragging}
                          />
                        </Holder>
                      </>
                    )}
                  </Draggable>
                )
              })
            }
            {provided.placeholder}
          </Section.Content>
        )
      }}
    </Droppable>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal);
};

export default List;
