import React from 'react';

import Input from './Input';
import MarkdownEditor from '@components/MarkdownEditor';

const Component = ({
  data,
  handleChange,
}) => {
  const { type, label, value, settings = [] } = data;

  const isRequired = settings.some((f) => f.type === 'required' && f.value);
  const placeholder = (settings.find((f) => f.type === 'placeholder') || {}).value;

  const props = {
    value,
    placeholder,
    isRequired,
    onChange: handleChange,
  }

  switch (type) {
    case 'title':
      return <Input {...props} />;
    case 'richText':
      return <MarkdownEditor {...props} />;
    default:
      return label;
  }
}

export default Component;
