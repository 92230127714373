import styled from 'styled-components';
import { ellipsis } from 'polished';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

export const Toolbar = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }
`;
