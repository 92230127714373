import React from 'react';
import API from '@api';
import { withContext } from '@context';
import { prepareObjectKey } from '@utils/helpers';
import { useInput, useSetState } from '@utils/hooks';
import { withModal, Modal } from '@components/Modal';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';

import { Content } from './styles';

const ModalComponent = ({
  title,
  closeFn,
  history,
  match,
  dispatch,
  contentEnvironments,
  contentLocales,
  contentTypes,
}) => {
  const { params } = match;

  const [state, setState] = useSetState({
    form: {
      name: null,
      apiId: null,
      modelObjectId: null,
    },
    isValid: false,
    isLoading: false,
  });

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });


    try {
      const payload = await API.saveContent({
        data: [],
        name: state.form.name,
        apiId: state.form.apiId,
        modelObjectId: state.form.modelObjectId,
        __action: 'CREATE',
      });

      await dispatch({
        type: 'CONTENT_ENTRY_SET',
        payload,
      });

      const currentLocale = contentLocales.find((f => f.isDefault));

      dispatch({
        type: 'CONTENT_ENTRY_SETTINGS_SET',
        payload: {
          locales: [currentLocale],
        },
      });

      setState({
        isLoading: false,
      });

      history.push({
        pathname: `/dashboard/${params.type}/${payload.original.objectId}`,
      });
    } catch (e) {
      console.error(e.message);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: e.message,
      });
    }
  }

  const checkValidation = (s) => {
    let isValid = true;

    Object.keys(s.form).forEach((key) => {
      if (!s.form[key] || s.form[key] === '') {
        isValid = false;
      }
    });

    return isValid;
  }

  const [name, nameInput] = useInput({
    label: 'Entry Name',
    type: "text",
    value: state.form.name,
    isRequired: true,
    isValid: state.form.name !== '',
    validationErrorMessage: 'cannot be empty',
    onChange: (value) => {
      setState(state => {
        state.form.name = value;
        state.isValid = checkValidation(state);
      });
    }
  });

  const [apiId, apiIdInput] = useInput({
    label: 'ApiId',
    type: "text",
    value: state.form.apiId,
    isRequired: true,
    isValid: state.form.apiId !== '',
    validationErrorMessage: 'cannot be empty',
    onChange: (value) => {
      setState(state => {
        state.form.apiId = prepareObjectKey(value);
        state.isValid = checkValidation(state);
      });
    }
  });

  const handleModelChange = (value) => {
    setState(state => {
      state.form.modelObjectId = value;
      state.isValid = checkValidation(state);
    });
  }

  const renderSelectModels = () => {
    const options = contentTypes.list.map((o) => {
      if (o.draft.isGlobalField) return null;
      return {
        key: o.draft.objectId,
        value: o.draft.name,
      }
    }).filter(f => f);

    return (
      <Dropdown
        label={'* Content Type'}
        options={options}
        selected={state.form.modelObjectId}
        onChange={handleModelChange} />
    )
  }

  return (
    <Modal.Container size='xs'>
      <Modal.Header title={title} />
      <Modal.Content>
        <Content>
          {nameInput}
          {apiIdInput}
          {renderSelectModels()}
        </Content>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: 'grey',
            size: 'md',
            borderRadius: '4px',
            minWidth: '100px',
          }}
        >Cancel</Button>
        <Button
          onClick={onSave}
          theme={{
            color: 'blue',
            size: 'md',
            borderRadius: '4px',
            minWidth: '100px',
            disabled: !state.isValid,
            isLoading: state.isLoading,
          }}
        >Create</Button>
      </Modal.Footer>
    </Modal.Container>
  );
}


export default withContext(['contentEnvironments', 'contentLocales', 'contentTypes'])(withModal(ModalComponent));
