import styled, { css } from 'styled-components';

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  padding-right: 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0, 0);
  background-color: var(--color-cs-bg);
  transition: all .2s;
  overflow: hidden;
`;

export const Settings = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
  transition: all 0s;
  overflow: hidden;

  ${({ isSection }) => isSection && `
    background-color: var(--color-cs-bg);
  `}
`;

export const Container = styled.div`
  position: relative;
  height: 20px;
  border-radius: 4px;

  &:hover {
    ${Settings} {
      opacity: 0;
      transition: all 0s .2s;
      pointer-events: none;
    }

    ${({ itemsCount }) => css`
      ${Menu} {
        width: ${itemsCount * 20}px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0, 0.1);
      }
    `}
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: var(--color-cs-fill-grey-3);
    transition: all .2s;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #557ff6;
      > svg {
        fill: #fff;
      }
    }

    > svg {
      fill: var(--color-cs-fill-grey-3);
    }
  }
`;

export const Title = styled.div`
  flex: 1;
  color: var(--color-cs-fill-grey-3);
  font-size: 14px;
`
