import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 390px;
  transform: translateX(100%);
  box-shadow: 0 2px 16px 0 rgba(153,155,168, 0.12);
  background-color: #fff;

  ${({ isMounted }) => css`
    animation: ${isMounted ? fadeIn : fadeOut} .1s forwards;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 0 30px;
  height: 64px;
  font-size: 18px;
  font-weight: 300;
  border-bottom: 1px solid #EAEDF3;

  span {
    margin-top: -4px;
    margin-bottom: 4px;
    color: #778CA2;
    font-size: 9px;
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 30px;
  overflow: auto;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 64px;
  border-top: 1px solid #EAEDF3;
`;

const fadeIn = keyframes`
  from {
    /* opacity: 0; */
    transform: translateX(100%);
  }
  to {
    /* opacity: 1; */
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    /* opacity: 1; */
    transform: translateX(0);
  }
  to {
    /* opacity: 0; */
    transform: translateX(100%);
  }
`;
