import React, { useState, useEffect } from 'react';
import { Container, Holder } from './styles';
import LoadingBar from './LoadingBar';
import Spinner from './Spinner';

const Loader = ({
  customRef,
  children,
  showLoading,
  delay,
  className,
  backgroundColor,
  type = 'spinner'
}) => {
  let refLoadingBar = React.useRef();
  let [stopAnimation, handleStopAnimation] = useState(false);

  let content;
  switch (type) {
    case 'progress':
      content = <LoadingBar ref={refLoadingBar} stopAnimation={stopAnimation} />;
      break;
    case 'spinner':
      content = <Spinner stopAnimation={stopAnimation} />;
      break;
    default:
      content = <Spinner stopAnimation={stopAnimation} />;
  }

  useEffect(() => {
    if (!showLoading) {
      if (type === 'progress') {
        return refLoadingBar.current.complete()
      }

      const timer = setTimeout(() => {
        handleStopAnimation(true);
      }, 1000);

      return () => clearTimeout(timer);
    }

    if (type === 'progress') {
      refLoadingBar.current.continousStart(0.1);
    }
  }, [showLoading]);


  return (
    <Container ref={customRef} className={className} >
      {children}

      <Holder
        showLoading={showLoading}
        backgroundColor={backgroundColor}
        delay={delay}
      >
        {content}
      </Holder>
    </Container>
  );
};

export default Loader;
