import storage from 'local-storage-fallback';

const initialState = {
  environments: null,
  locales: null,
};

const contentEntrySettingsSet = (state, payload) => {
  const entrySettings = { ...state, ...payload };

  const cs = storage.getItem('contentship');
  const contentship = cs !== "" ? JSON.parse(cs) : {};

  contentship['config'] = {
    ...contentship['config'],
    entrySettings: {
      locales: entrySettings.locales,
    }
  };

  storage.setItem('contentship', JSON.stringify(contentship));

  return entrySettings;
};

const effects = {
  CONTENT_ENTRY_SETTINGS_SET: contentEntrySettingsSet,
};

export default {
  initialState,
  effects
};
