import immer from 'immer';
import _ from 'lodash';
import { transformTypeModel } from '@api/helpers';
import { removeKeys, checkRequiredSettings } from '@utils/helpers';

const initialState = {
  draft: {
    name: "",
    state: [],
    locale: "",
    data: [],
    __meta: {
      isNew: true,
      canSave: false,
    }
  },
  original: {
    name: "",
    state: [],
    locale: "",
    data: [],
    __meta: {
      isNew: true,
      canSave: false,
    }
  },
};

const contentEntryInit = (state, payload) => {
  // console.log('contentEntryInit');
  return initialState;
};

const contentEntrySet = (state, payload) => {
  // console.log('contentEntrySet');
  return payload;
};

const areEqual = (state, draftState) => {
  state = {
    name: state.original.name,
    data: state.original.data,
  };

  removeKeys(state);

  draftState = {
    name: draftState.draft.name,
    data: JSON.parse(JSON.stringify(draftState.draft.data)),
  };

  removeKeys(draftState)

  const isDataEqual = _.isEqual(state, draftState);
  return isDataEqual;
}

const contentEntryUpdate = (state, payload) => {
  // console.log('contentEntryUpdate');

  payload = immer(payload, draftState => {
    const isNew = false;
    let canSave = checkRequiredSettings(draftState.draft);

    if (areEqual(state, draftState)) {
      canSave = false;
    }

    draftState.draft.__meta.isNew = isNew;
    draftState.draft.__meta.canSave = canSave;
  });

  return {
    ...state,
    ...payload
  };
};

const contentEntryDelete = (state, payload) => {
  // console.log('contentEntryDelete');
  return { ...state, ...payload }
};

const contentEntryReset = (state, payload) => {
  // console.log('contentEntryReset');
  return {
    ...transformTypeModel(state.original),
  }
};

const effects = {
  CONTENT_ENTRY_INIT: contentEntryInit,
  CONTENT_ENTRY_SET: contentEntrySet,
  CONTENT_ENTRY_UPDATE: contentEntryUpdate,
  CONTENT_ENTRY_DELETE: contentEntryDelete,
  CONTENT_ENTRY_RESET: contentEntryReset,
};

export default {
  initialState,
  effects
};
