import React from 'react';
import Toolbar from '@components/Toolbar';
import pages from '@pages';
import { Layout } from './styles';

const { routes, sidebar, toolbar } = pages;

const Component = () => {
  return (
    <Layout.Container>
      <Toolbar>
        {toolbar}
      </Toolbar>
      <Layout.Main>
        {sidebar}
        <Layout.ContentHolder>
          <Layout.Content>
            {routes}
          </Layout.Content>
        </Layout.ContentHolder>
      </Layout.Main>
    </Layout.Container>
  )
}

export default Component;
