import styled, { keyframes, css } from 'styled-components';
import { darken, lighten } from 'polished';

const colors = {
  red: '#f62943',
  green: '#1bb933',
  blue: '#0092ff',
  'dark-blue': '#3173b9',
  grey: 'rgba(197, 208, 222, 0.08)',
}

const sizes = {
  xs: `
    > span {
      height: 16px;
      min-width: 16px;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: .5px;
      text-transform: uppercase;
    }

    .spinner {
      &:before {
        width: 8px;
        height: 8px;
        border-width: 2px;
      }
    }

    .button-content {
      padding: 0 8px;
    }

    svg {
      fill: #fff;
      width: 8px;
      height: 8px;
      margin-right: -4px;
      margin-left: 6px;
    }
  `,
  sm: `
    > span {
      height: 26px;
      min-width: 26px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .5px;
      font-family: sans-serif;
    }

    .spinner {
      &:before {
        width: 14px;
        height: 14px;
        border-width: 2px;
      }
    }

    svg {
      fill: #fff;
      width: 12px;
      height: 12px;
      margin-right: -4px;
      margin-left: 8px;
    }

    .button-content {
      padding: 0 12px;
    }
  `,
  md: `
    > span {
      height: 36px;
      min-width: 36px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .5px;
      font-family: sans-serif;
    }

    .spinner {
      &:before {
        width: 18px;
        height: 18px;
        border-width: 4px;
      }
    }

    .button-content {
      padding: 0 14px;
    }

    svg {
      fill: #fff;
      width: 14px;
      height: 14px;
      margin-right: -6px;
      margin-left: 10px;
    }
  `,
  lg: `
    > span {
      height: 46px;
      min-width: 46px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: .5px;
      font-family: sans-serif;
    }

    .spinner {
      &:before {
        width: 26px;
        height: 26px;
        border-width: 4px;
      }
    }

    .button-content {
      padding: 0 16px;
    }

    svg {
      fill: #fff;
      width: 18px;
      height: 18px;
      margin-right: -8px;
      margin-left: 14px;
    }
  `,
}

const Button = styled.button`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 0;
  border: 1px solid transparent;
  background: transparent;
  text-decoration: none;
  white-space: nowrap;
  appearance: none;
  cursor: pointer;
  transition: all .2s ease-in;
  overflow: hidden;

  ${({ theme }) => theme.size && sizes[theme.size]}

  > span {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;

    ${({ theme }) => !theme.hasChildren && `svg { margin: 0; }`}

    ${({ theme }) => theme.minWidth && `min-width: ${theme.minWidth};`}
  }

  .spinner {
    position: relative;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-style: solid;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      ${() => css`
        animation: ${spinnerAnimation} .6s infinite linear;
      `}
    }
  }

  ${({ theme }) => `
    ${theme.showLoader ? `pointer-events: none;` : ''}
    ${theme.spacing ? `margin: ${theme.spacing};` : ''}
    border-radius: ${theme.borderRadius ? theme.borderRadius : '100px' };

    ${theme.color && colors[theme.color] ? `
      color: #fff;
      border-color: ${colors[theme.color]};
      background: ${colors[theme.color]};

      .spinner {
        &:before {
          border-color: rgba(255,255,255, .4);
          border-top-color: rgba(255,255,255, .8);
        }
      }

      &:hover {
        border-color: ${darken(.05, colors[theme.color])};
        background: ${darken(.05, colors[theme.color])};
      }

      &:active {
        border-color: ${darken(.15, colors[theme.color])};
        background: ${darken(.15, colors[theme.color])};
      }
    ` : ''}

    ${theme.color === 'grey' ? `
      color: #333c48;
      border: 1px solid #dfe3e9;

      &:hover {
        background: ${darken(.4, colors[theme.color])};
      }

      &:active {
        background: ${darken(.5, colors[theme.color])};
      }
    `: ''}

    ${theme.disabled ? `
      color: ${darken(.10, '#dfe3e9')};
      cursor: default;
      border: 1px solid #dfe3e9;
      background-color: #fff;
      pointer-events: none;
      transition: all 0s;

      > span {
        svg {
          fill: #dfe3e9;
        }
      }

      &:hover {
        background: #fff;
        border: 1px solid #dfe3e9;
      }
    ` : ''}
  `}
`;

const spinnerAnimation = keyframes`
  0% { transform: translate(-50%, -50%) otate(0); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

export default Button;
