import React from 'react';
import { withContext } from '@context';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from '@components/Icon';
import Placeholder from './placeholder';
import { Container, List, Item } from './styles';
import { contentTypes } from '@utils/constants';


const Sidebar = ({
  match,
  droppableIndex,
  contentGlobalFields,
}) => {
  const { params } = match;
  const isGlobalField = params.type === 'global-fields';

  let items = contentTypes;
  if (isGlobalField) {
    items = contentTypes.filter(f => f.type !== 'globalField');
  }

  if ((contentGlobalFields.list || []).length === 0) {
    items = items.filter(f => f.type !== 'globalField');
  }


  return (
    <Container>
      <Droppable
        droppableId="sidebar"
        isDropDisabled={true}
      >
        {(provided, snapshot) => (
          <List ref={provided.innerRef}>
            {items.map((item, i) => (
              <Draggable draggableId={`csType-${item.type}`} index={i} key={i}>
                {(provided, snapshot) => (
                  <>
                    {
                      droppableIndex === i
                        ? (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Placeholder
                              item={item}
                            />
                          </div>
                        )
                        : (
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >

                            <Icon name={item.__meta.icon} fill="#8091a5" size={[16]} />
                            <div className="info">
                              <span className="label">{item.__meta.label}</span>
                              <span className="desc">{item.__meta.desc}</span>
                            </div>
                          </Item>
                        )
                    }

                    {snapshot.isDragging && (
                      <Item isCloned>
                        <Icon name={item.__meta.icon} fill="#8091a5" size={[16]} />

                        <div className="info">
                          <span className="label">{item.__meta.label}</span>
                          <span className="desc">{item.__meta.desc}</span>
                        </div>
                      </Item>
                    )}

                    {provided.placeholder}
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Container>
  )
}

export default withContext(['contentGlobalFields'])(Sidebar);
