import React from 'react';
import { withContext } from '@context';
import Logo from '@components/Logo';
import { Toolbar, Header, Container, Content } from './styles';

const Component = ({ history, theme, dispatch, children }) => {
  const toggleHandler = () => {
    dispatch({
      type: 'THEME_UPDATE',
      payload: {
        sidebarExpanded: !theme.sidebarExpanded
      }
    });
  }

  return (
    <Toolbar>
      <Header expand={theme.sidebarExpanded}>
        <Logo />
      </Header>
      <Container>
        <Content>
          {children}
        </Content>
      </Container>
    </Toolbar>
  );
};


export default withContext(['theme', 'user'])(Component);
