import React, { useEffect } from "react";
import { withContext } from '@context';
import _ from 'lodash';
import API from '@api';
import { useSetState } from '@utils/hooks';
import Loader from '@components/Loader';
import List from "./list";
import Heading from './components/section/heading';

import { Container, Content, Section } from './styles';

const Editor = ({
  history,
  location,
  match,
  dispatch,
  contentEntrySettings,
  contentEnvironments,
  contentLocales,
  contentEntry: {
    draft: contentEntryDraft,
  },
}) => {
  const { params: { objectId } } = match;

  const [state, setState] = useSetState({
    showLoading: true,
  });

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    fetchData();
  }, [contentEntrySettings])

  const fetchData = async () => {
    setState(state => {
      state.showLoading = true;
    });

    try {
      const localeObjectId = contentEntrySettings.locales[0].objectId;

      const payload = await API.getContent({
        objectId,
        localeObjectId,
      });

      dispatch({
        type: 'CONTENT_ENTRY_SET',
        payload,
      });

      setState(state => {
        state.showLoading = false;
      });
    } catch (e) {
      console.error(e.message);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: 'Something is wrong. Please contact us!',
      });

      history.push("/dashboard/entries");
    }
  };

  return (
    <Loader showLoading={state.showLoading} type="progress">
      <Container>
        <Content>
          {contentEntryDraft.data.map((item, sectionIndex) => (
            <Section.Container key={item.id}>
              <Heading sectionIndex={sectionIndex} />
              <List item={item} />
            </Section.Container>
          ))}
        </Content>
      </Container>
    </Loader>
  );
};

export default withContext(['contentEntrySettings', 'contentEnvironments', 'contentLocales', 'contentEntry'])(Editor);
