import _ from 'lodash';
import { contentSections, contentTypes } from '@utils/constants';

export const regExpFromString = (q) => {
  let flags = q.replace(/.*\/([gimuy]*)$/, '$1');
  if (flags === q) flags = '';
  let pattern = (flags ? q.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1') : q);
  try { return new RegExp(pattern, flags); } catch (e) { return null; }
}

export const getNumbers = (input, toNumber = false) => {
  const digits = input.match(/[0-9]+/g);
  if (toNumber) {
    return Number(digits);
  }
  return digits;
}

export const generateUID = (len = 16) => {
  return `${'x'.repeat(len)}`.replace(/[x]/g, () => {
    return (Math.random() * 16 | 0).toString(16);
  }).toLowerCase();
}

export const stripHTML = (text) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
}

export const prepareObjectKey = (text) => {
  return text.replace(/ /g, "_").toLowerCase();
}

export const removeKeys = (object, keys = ["__meta"]) => {
  if (object instanceof Array) {
    object.forEach((item) => {
      removeKeys(item, keys)
    });
  }
  else if (typeof object === 'object') {
    Object.getOwnPropertyNames(object).forEach((key) => {
      if (keys.includes(key)) delete object[key];
      else removeKeys(object[key], keys);
    });
  }
}

export const unifyTypes = (object) => {
  if (object instanceof Array) {
    object.forEach((item) => {
      unifyTypes(item);
    });
  }
  else if (typeof object === 'object') {
    for (var x in object) {
      if (object.hasOwnProperty(x)) {
        if (typeof object[x] == 'object') {
          unifyTypes(object[x]);
        }

        if (x === 'type') {
          const defaults = contentTypes.find(t => t.type === object.type) || {};
          object.__meta = {
            ...(defaults.__meta),
            isNew: false,
          };


          if (defaults.settings) {
            object.settings = defaults.settings.map(item => {
              const d = object.settings.find(f => f.type === item.type);
              return {
                ...item,
                ...d,
              }
            })
          }
        }
      }
    }
  }
};

export const removeGlobalFieldsSubItems = (object) => {
  if (object instanceof Array) {
    object.forEach((item) => {
      removeGlobalFieldsSubItems(item);
    });
  }
  else if (typeof object === 'object') {
    for (var x in object) {
      if (object.hasOwnProperty(x)) {
        if (x === 'type' && object[x] === 'globalField') {
          object.subItems = [];
        }

        if (typeof object[x] == 'object') {
          removeGlobalFieldsSubItems(object[x]);
        }
      }
    }
  }
}


export const checkRequiredSettings = (obj) => {
  const object = obj;
  let canSave = true;

  (function innerFn(object) {
    if (object instanceof Array) {
      object.forEach((item) => {
        innerFn(item);
      });
    }
    else if (typeof object === 'object') {
      for (var x in object) {
        if (object.hasOwnProperty(x)) {
          if (typeof object[x] == 'object') {
            innerFn(object[x]);
          }

          if (x === 'settings') {
            const required = object[x].find(f => f.type === 'required');
            if (required && required.value) {
              if (!object.value || object.value === "") {
                canSave = false;
              }
            }
            const validationRegex = object[x].find(f => f.type === 'validationRegex');
            if (validationRegex && validationRegex.value) {
              const rx = RegExp.apply(RegExp, validationRegex.value.match(/\/(.*)\/(.*)/).slice(1));

              if (!object.value || !rx.test(object.value)) {
                canSave = false;
              }
            }
          }
        }
      }
    }
  })(object);

  return canSave;
};
