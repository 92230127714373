import React, { useState, useEffect } from 'react';
import { generateUID } from '@utils/helpers';
import { Container, SwitchContainer } from './styles';

const Switch = ({ label, isDisabled, checked = false, onClick }) => {
  const [uid, setUID] = useState(generateUID(6));

  return (
    <Container>
      {label && <label htmlFor={uid}>{label}</label>}
      <SwitchContainer>
        <input
          checked={checked}
          id={uid}
          type="checkbox"
          disabled={isDisabled}
          onChange={(e) => !isDisabled && onClick(e.target.checked)}
        />
        <label htmlFor={uid} />
      </SwitchContainer>
    </Container>
  )
}

export default Switch;
