import styled from 'styled-components';
import { ellipsis } from 'polished';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 180px;
  width: 100%;

  label {
    font-size: 10px;
    margin-bottom: 8px;
    letter-spacing: 1px;
    color: var(--color-cs-fill-grey-2);
    text-transform: uppercase;

    span {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .error-message {
    padding-top: 4px;
    color: #FE4D97;
    font-size: 12px;
    font-style: italic;
  }
`;

export const CollapseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  cursor: pointer;

  > svg {
    transition: transform .2s;
  }

  ${({ isOpen }) => isOpen && `
    > svg {
      transform: rotate(180deg);
    }
  `}

  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;

    > svg {
      opacity: .2;
    }
  `}
`;

export const SelectEl = styled.div`
  position: relative;
  display: inline-flex;
  padding: 0 38px 0 14px;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  color: #3E3F42;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    border: 1px solid #E8ECEF;
    background-color: #fff;
    pointer-events: none;
    z-index: -1;

    ${({ isOpen }) => isOpen && `
      border-radius: 4px 4px 0 0;
      border-bottom-color: transparent;
    `}
  }
`;

export const OptionsHolder = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  left: 0;
  margin-top: -1px;
  max-height: 0;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  transition:
    min-height .4s cubic-bezier(0, 1, 0, 1) -.1s,
    max-height .4s cubic-bezier(0, 1, 0, 1) -.1s
  ;
  z-index: 1;

  ${({ isOpen }) => isOpen && `
    max-height: 9999px;
    transition:
      min-height .4s 0s cubic-bezier(0.5, 0, 1, 0),
      max-height .4s 0s cubic-bezier(0.5, 0, 1, 0)
    ;
  `}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0 0 4px 4px;
    border: 1px solid #E8ECEF;
    background-color: #fff;
    pointer-events: none;
    z-index: -1;
  }
`;

export const Options = styled.div`
  max-height: 108px;
  overflow: hidden;
  overflow-y: auto;

  > div {
    ${ellipsis('100%')}
    display: block;
    align-items: center;
    padding: 0 14px;
    line-height: 36px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #4D7CFE;
    }
  }
`;

export const Extra = styled.span`
  font-size: 12px;
  font-style: italic;
`;
