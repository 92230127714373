import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import immer from 'immer';
import { withContext } from '@context';
import { prepareObjectKey } from '@utils/helpers';
import Icon from '@components/Icon';
import ConfigSidebar from '../../shared/configSidebar';
import HeadingConfigMenu from '../../shared/headingConfigMenu';
import SectionSidebar from './sidebar';
import { Container, Title, CollapseIcon } from './styles';

const SectionHeading = ({
  sectionIndex,
  dragHandleProps,
  dispatch,
  contentType: {
    draft: contentTypeDraft,
  }
}) => {
  const { isGlobalField } = contentTypeDraft;
  const data = contentTypeDraft.data[sectionIndex];

  if (isGlobalField) {
    return (
      <>
        <span {...dragHandleProps} />
        <div style={{ height: 10 }} />
      </>
    )
  }

  const isCustomKey = data.apiId !== prepareObjectKey(data.label);
  const { isOpen, isNew } = data.__meta;
  const isDisabled = data.subItems.length === 0;

  const init = {
    data,
    isModified: false,
    isCustomKey,
    isOpen,
    isNew,
    isDisabled,
  };

  const [state, setState] = useState(init);
  const [initialState, setInitialState] = useState();
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const data = contentTypeDraft.data[sectionIndex];
    const isCustomKey = data.apiId !== prepareObjectKey(data.label);
    const { isOpen, isNew } = contentTypeDraft.data[sectionIndex].__meta;
    const isDisabled = data.subItems.length === 0;

    const init = {
      data,
      isModified: false,
      isCustomKey,
      isOpen,
      isNew,
      isDisabled,
    };

    setInitialState(init);
    setState(init);
  }, [contentTypeDraft]);

  const handleItemIdChange = (value) => {
    const payload = immer(state, draftState => {
      draftState.data.apiId = prepareObjectKey(value);
      draftState.isModified = true;
      draftState.isCustomKey = value !== '';
    });

    setState(payload);
  }

  const handleItemLabelChange = (value) => {
    const payload = immer(state, draftState => {
      if (!draftState.isCustomKey || draftState.isNew) {
        draftState.data.apiId = prepareObjectKey(value);
        draftState.isNew = false;
        draftState.isCustomKey = false;
      }

      draftState.data.label = value;
      draftState.isModified = true;
    });

    setState(payload);
  }

  const checkValidation = () => {
    let isValid = state.data.apiId !== '' && state.isModified;

    [...state.data.__meta.required].forEach((key) => {
      if (isValid && state.data.subItems[key] === '') {
        isValid = false;
      }
    });

    return isValid;
  }

  const handleDelete = () => {
    const draft = immer(contentTypeDraft, draftState => {
      draftState.data.splice(sectionIndex, 1);
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });

    dispatch({
      type: 'SNACKBAR_ADD',
      payload: `Section ${state.data.label ? `<b>${state.data.label}</b>` : ''} was deleted!`
    });
  }

  const handleCancel = () => {
    setShowSettings(false);
    setState(initialState);
  }

  const handleConfirm = async () => {
    const draft = immer(contentTypeDraft, draftState => {
      draftState.data[sectionIndex].apiId = state.data.apiId;
      draftState.data[sectionIndex].label = state.data.label;
      draftState.data[sectionIndex].__meta.isNew = false;
    });

    setShowSettings(false);

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });

    dispatch({
      type: 'SNACKBAR_ADD',
      payload: `Successfully updated!`
    });
  }

  const toggleSectionHandler = () => {
    const draft = immer(contentTypeDraft, draftState => {
      draftState.data[sectionIndex].__meta.isOpen = !state.isOpen;
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });
  }

  const sectionName = state.data.label || <span>Section Name ...</span>;

  return (
    <Container>
      <Title>
        <CollapseIcon onClick={toggleSectionHandler} isOpen={state.isOpen} isDisabled={state.isDisabled}>
          <Icon name="icon-arrow-right" fill="var(--color-cs-fill-grey-3)" size={[8]} />
        </CollapseIcon>
        <div onClick={() => setShowSettings(true)}>
          {sectionName}
        </div>
      </Title>

      <HeadingConfigMenu
        isSection
        itemsCount={2}
        className="section-heading"
        handleDelete={handleDelete}
        handleSettings={setShowSettings}
        dragHandleProps={dragHandleProps}
      />

      <ConfigSidebar
        type={state.data.label}
        visible={showSettings}
        handleConfirm={handleConfirm}
        isModified={state.isModified}
        isValid={checkValidation()}
        closeFn={handleCancel}
      >
        <SectionSidebar
          data={state.data}
          isModified={state.isModified}
          isNew={state.isNew}
          handleItemIdChange={handleItemIdChange}
          handleItemLabelChange={handleItemLabelChange}
        />
      </ConfigSidebar>
    </Container>
  )
};

export default withContext(['contentType'])(SectionHeading);
