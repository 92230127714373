import React, { memo, useState } from "react";
import _ from 'lodash';
import { useInterval } from '@utils/hooks';
import Cell from "./Cell";

const Columns = ({
  index,
  row,
  contentEntrySettings,
  environments,
  locales,
  columns,
  loaded,
}) => {

  const content = columns.map((field, i) => {
    return (
      <Cell
        key={field}
        row={row}
        contentEntrySettings={contentEntrySettings}
        environments={environments}
        locales={locales}
        field={field}
        loaded={loaded}
      />
    )
  });

  return content;
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Columns, areEqual);
