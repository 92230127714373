import React, { useState } from 'react';
import Icon from '@components/Icon';
import Modal from './modal';
import { CreateButton } from './styles';

const Create = ({
  label
}) => {
  const [visible, setVisible] = useState(false);

  const toggleModal = () => {
    setVisible(!visible);
  }

  return (
    <>
      <CreateButton onClick={toggleModal}>
        <Icon name="icon-circle-plus" size={[24]} />
        {label}
      </CreateButton>
      <Modal
        title={label}
        visible={visible}
        closeFn={toggleModal}
      />
    </>
  );
}


export default Create;
