import React, { useState, useEffect } from 'react';
import { generateUID } from '@utils/helpers';
import Input from '@components/Input';

const useInput = ({
  WrappedComponent = Input,
  label,
  type = "text",
  value: v,
  autoFocus,
  placeholder,
  onChange,
  className,
  isRequired,
  isValid,
  validationErrorMessage,
}) => {
  const [uid, setUID] = useState(generateUID(6));
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(v)
  }, [v]);

  const onChangeHandler = (e) => {
    setValue(e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }
  }

  const input = (
    <WrappedComponent
      uid={uid}
      label={label}
      className={className}
      value={value || ""}
      onChange={onChangeHandler}
      autoFocus={autoFocus}
      placeholder={placeholder}
      type={type}
      isRequired={isRequired}
      isValid={isValid}
      validationErrorMessage={validationErrorMessage}
    />
  );
  return [value, input];
}

export default useInput;
