import React, { memo, useRef, useState } from 'react';
import _ from 'lodash';
import useOutsideClick from "@utils/hooks/useOutsideClick";
import Icon from '@components/Icon';
import { Container, CollapseIcon, SelectEl, OptionsHolder, Options, Extra } from './styles';

const Dropdown = ({
  WrapperComponent = Container,
  label,
  options,
  selected,
  onChange,
  noOption = '...'
}) => {
  const refContainer = useRef();

  const [isOpen, toggleOpen] = useState(false);
  const onClick = () => toggleOpen(!isOpen);

  useOutsideClick(refContainer, () => {
    toggleOpen(false);
  });

  const defaultValue = options.find(f => f.key === selected) || { value: noOption };
  const isDisabled = options.length === 0;

  return (
    <WrapperComponent ref={refContainer}>
      {label && <label onClick={onClick}>{label}</label>}
      <SelectEl onClick={onClick} isOpen={isOpen}>
        <span>{defaultValue.value} {defaultValue.extra && <Extra>({defaultValue.extra})</Extra>}</span>
        <CollapseIcon isOpen={isOpen} isDisabled={isDisabled}>
          <Icon name="icon-arrow-bottom" fill="var(--color-cs-fill-grey-3)" size={[8]} />
        </CollapseIcon>
        <OptionsHolder isOpen={isOpen}>
          <Options>
            {
              options
                // .filter(f => f.key !== selected)
                .map(({ key, value, extra }) => {
                  return (
                    <div key={key} onClick={() => onChange(key)}>{value} {extra && <Extra>({extra})</Extra>}</div>
                  )
                })
            }
          </Options>
        </OptionsHolder>
      </SelectEl>
    </WrapperComponent>
  );
};


const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Dropdown, areEqual);
