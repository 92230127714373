import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { List } from 'react-virtualized';

export const Container = styled.div`
  flex: 1;
  position: relative;

  .ListContainer {
    padding-right: 36px;

    > div {
      border-radius: 4px;
      background-color: #fff;
    }
  }

  .grid-row {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    ${({ gridTemplateColumns }) => gridTemplateColumns && `
      grid-template-columns: ${gridTemplateColumns};
    `}
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  color: var(--color-cs-fill-grey-2);
  border-bottom: 1px solid #EAEDF3;
  z-index: 1;

  .grid-row {
    display: grid;
    height: 30px;
    padding-bottom: 6px;

    > div {
      display: flex;
      align-items: center;
      > div {
        padding: 0 20px;
      }
    }
  }
`;

export const ListStyled = styled(List)`
  padding-right: 36px;
`;

export const Loading = styled.div`
  flex: 1;
  margin: 0;
  height: 18px;
  border-radius: 4px;
  pointer-events: none;
  background-color: var(--color-cs-fill-grey-1);
`;

export const Row = styled.div`
  display: grid;
  align-items: center;
  position: relative;
  height: 70px;
  background-color: #fff;
  z-index: 1;

  ${({ loaded }) => loaded && `
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      background-color: transparent;
      transition: all .2s;
      pointer-events: none;
      z-index: -1;
    }

    &:hover {
      cursor: pointer;
      &:before {
        background-color: var(--color-cs-fill-grey-1);
      }
    }
  `}


  &:after {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    height: 1px;
    background-color: var(--color-cs-fill-grey-0);
    pointer-events: none;
    z-index: -2;
  }
`;

export const Environments = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 26px;
  font-size: 12px;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 4px;
    fill: ${rgba('#ff8800', .6)};
  }
`;

export const Locales = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 26px;
  font-size: 12px;
  white-space: nowrap;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 4px;
    fill: ${rgba('#ff8800', .6)};
  }

  .fallback {
    font-style: italic;
  }
`;

export const VersionsCount = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 40px;
`;

export const ToolTipHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  background-color: rgba(0,0,0, .8);
`;
