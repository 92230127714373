import React, { useEffect, useRef } from "react";
import { withContext } from '@context';
import _ from 'lodash';
import immer from 'immer';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import API from '@api';
import { generateUID } from '@utils/helpers';
import { contentSections } from '@utils/constants';
import { useSetState } from '@utils/hooks';
import Loader from '@components/Loader';
import Button from '@components/Button';

import Sidebar from './components/sidebar';
import { getSortedData, findPath, getByPath } from "./utils";
import List from "./list";
import Heading from './components/section/heading';

import { Container, Content, Section } from './styles';

const Editor = ({
  history,
  match,
  dispatch,
  contentType: {
    draft: contentTypeDraft,
  }
}) => {
  const refContent = useRef();

  const { params } = match;
  const { objectId } = params;
  const isGlobalField = params.type === 'global-fields';

  const [state, internalSetState] = useSetState({ showLoading: true });

  const setState = (func) => {
    internalSetState(prev => immer(prev, func));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = await API.getModel({ objectId });

        // console.log('payload', payload.draft);

        dispatch({
          type: 'CONTENT_TYPE_SET',
          payload,
        });

        setState(state => {
          state.showLoading = false;
        });

        if (isGlobalField && !payload.original.isGlobalField) {
          history.replace(`/dashboard/types/${payload.original.objectId}`);
        }

      } catch (e) {
        console.error(e.message);

        dispatch({
          type: 'SNACKBAR_ADD',
          payload: 'Something is wrong. Please contact us!',
        });

        history.push(`/dashboard/${params.type}`);
      }
    };


    fetchData();
  }, []);

  const onBeforeCapture = (result) => {
    if (result.type !== 'section') {
      setState(state => {
        state.isDragStart = true;
      });
    }
  }

  const onDragStart = (result) => {
    // disabled for groups
    const draggableId = result.draggableId.split("-");

    setState(state => {
      if (!result.draggableId.startsWith('csType')) {
        const path = findPath(contentTypeDraft.data, result.draggableId);
        path.pop();
        const target = getByPath(contentTypeDraft.data, path);

        if (target.type === 'group' || target.type === 'globalField') {
          state.isDropDisabledForGroups = true;
        }
      }

      if (draggableId[0] === "csType") {
        state.droppableIndex = result.source.index;
      }

      return state
    });
  }

  const onDragEnd = async (result) => {
    // console.info('@@@ result @@@', result);

    setState(state => {
      state.isDragStart = false;
      state.isDropDisabledForGroups = false;
      state.droppableIndex = null;
    });

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const draft = getSortedData(result, contentTypeDraft);

    const scrollTo = refContent.current.scrollTop;

    await setState(state => {
      // https://github.com/atlassian/react-beautiful-dnd/issues/1591
      state.hack = true;
    });

    await dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      },
    });

    await setState(state => {
      // https://github.com/atlassian/react-beautiful-dnd/issues/1591
      state.hack = false;
    });

    // because of the hack we should scroll the container to last position
    refContent.current.scrollTo(0, scrollTo)
  };

  const handleNewSection = () => {
    const draft = immer(contentTypeDraft, draftState => {
      const uid = generateUID(6);
      draftState.data.push({
        ...contentSections[0],
        id: uid, // override the id
        apiId: uid, // override the apiId
      });
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });
  }

  return (
    <Loader showLoading={state.showLoading} type="progress">
      <Container>
        <DragDropContext onBeforeCapture={onBeforeCapture} onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Content id="page-content" ref={refContent}>
            <Droppable droppableId="droppable" type="section">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  {contentTypeDraft.data.map((item, sectionIndex) => (
                    <Draggable key={item.id} draggableId={item.id} index={sectionIndex}>
                      {(provided, snapshot) => (
                        <Section.Container
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Heading
                            sectionIndex={sectionIndex}
                            dragHandleProps={provided.dragHandleProps}
                          />

                          {
                            // https://github.com/atlassian/react-beautiful-dnd/issues/1591
                            !state.hack && (
                              <List
                                provided={provided}
                                snapshot={snapshot}
                                item={item}
                                isDragStart={state.isDragStart}
                                isDropDisabledForGroups={state.isDropDisabledForGroups}
                              />
                            )
                          }
                        </Section.Container>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {
              !contentTypeDraft.isGlobalField && (
                <Button
                  style={{
                    alignSelf: 'flex-start'
                  }}
                  onClick={handleNewSection}
                  theme={{
                    color: 'blue',
                    size: 'md',
                    borderRadius: '4px',
                    spacing: '30px 0 30px 30px',
                    minWidth: '100px',
                  }}
                >Create a section</Button>
              )
            }
          </Content>
          <Sidebar droppableIndex={state.droppableIndex} />
        </DragDropContext>
      </Container>
    </Loader>
  );
};

export default withContext(['contentType'])(Editor);
