import styled, { keyframes, css } from 'styled-components';
import { rgba, darken, shade, tint } from 'polished';

export const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;

  > svg {
    transition: transform .2s;
  }

  ${({ isOpen }) => isOpen && `
    > svg {
      transform: rotate(90deg);
    }
  `}

  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;

    > svg {
      opacity: .2;
    }
  `}
`;

export const TypeIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  border-radius: 2px;
  background-color: var(--color-cs-fill-grey-4);
  transition: all .2s;

  > svg {
    transition: all .2s;
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 8px 12px 16px;
  border-radius: 4px 6px 6px 4px;
  background-color: #fff;
  transition: background-color .2s;
  overflow: hidden;
  z-index: 1;

  &.globalField {
    background-color: ${rgba('#fcfcff', .6)};
  }

  ${({ isGlobalField }) => isGlobalField && `
    // pointer-events: none !important;
  `}

  ${({ isDragStart }) => isDragStart && `
    background-color: #fff;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px 6px 6px 4px;
    border: 1px solid ${rgba('#000', 0.04)};
    z-index: -1;
    transition: border-color .2s;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    border-left: 3px solid ${rgba('#e1e3eb', .6)};
    z-index: -1;
    transition: border-color .2s;
    pointer-events: none;

    ${({ isInGroup }) => isInGroup && `
      border-color: #e1e3eb;
    `}

    ${({ isDragging }) => isDragging && `
      border-color: #557ff6;
      `}

    ${({ isThisAglobalField }) => isThisAglobalField && `
      border-color: ${rgba('#ecade5', .6)};
    `}
  }
`

export const Item = {
  Holder: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 16px 18px 16px 34px;
    background-color: #fff;

    ${({ isThisAglobalFieldChild }) => (isThisAglobalFieldChild) && `
      background: transparent;
    `}

    ${({ isDragging, isPlaceholder, isThisAglobalFieldChild }) => (isDragging || isPlaceholder) && !isThisAglobalFieldChild && `
      background-color: transparent;

      ${TypeIcon} {
        background-color: #557ff6;
        > svg {
          fill: #fff;
        }
      }
    `}

    ${({ isInGroup }) => isInGroup && `
      margin: 0;
      padding: 0;
    `}

    ${({ isSectionOpen }) => !isSectionOpen && `
      opacity: 0;
      pointer-events: none;
    `}

    ${({ isDragStart, isThisAglobalFieldChild }) => (!isDragStart && !isThisAglobalFieldChild) && `
      &:hover {
        & > ${ItemContainer} {
          &:after {
            transition: border-color .4s;
            border-color: rgba(85, 127, 246, .6);
          }

          & > div:first-child {
            ${TypeIcon} {
              background-color: #557ff6;
              > svg {
                fill: #fff;
              }
            }
          }
        }
      }
    `}
  `,
  Header: {
    Container: styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 20px;
    `,
    Top: styled.div`
      display: flex;
      justify-content: space-between;
    `,
    Title: styled.div`
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-cs-fill-grey-3);
      cursor: pointer;

      > span {
        margin-right: 4px;
      }
    `,
    SubTitle: styled.div`
      display: flex;
      align-items: center;
      padding-left: 34px;
      color: var(--color-cs-fill-grey-2);
      font-size: 10px;
      letter-spacing: 1;

      > span {
        cursor: pointer;
      }
    `,
  },
  ContnentContainer: styled.div`
    display: flex;
    padding: 10px 14px 0 34px;
  `,
  Content: styled.div`
    flex: 1;

    h3 {
      line-height: 16px;
      font-weight: 300;
      font-size: 12px;
      font-style: italic;
      color: #8091a5;
      cursor: pointer;
      cursor: pointer;
    }
  `,
};

export const GroupHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 14px 8px 14px 0;
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
  transition:
    min-height .4s cubic-bezier(0, 1, 0, 1) -.1s,
    max-height .4s cubic-bezier(0, 1, 0, 1) -.1s
  ;
  z-index: 1;

  ${({ isOpen }) => !isOpen && `
    min-height: 0;
    max-height: 0;
  `}

  ${({ isOpen }) => isOpen && `
    max-height: 9999px;
    transition:
      min-height .4s 0s cubic-bezier(0.5, 0, 1, 0),
      max-height .4s 0s cubic-bezier(0.5, 0, 1, 0)
    ;
  `}

  > div {
    transition: opacity .2s;
  }

  ${({ isOpen }) => !isOpen && `
    > div {
      opacity: 0;
      pointer-events: none;
    }
  `}

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 0;
    border-radius: 4px;
    pointer-events: none;
    z-index: -1;

    ${({ isDragStart, isInGroup, isOpen }) => (isDragStart && isInGroup && isOpen) && `
      border: 1px dashed #c3cbda;
      background-color: rgba(33, 150, 243, 0.1);
    `}
  }
`;

export const Group = styled.div`
  padding: 0 44px 0 60px;

  ${({ isInGroup }) => isInGroup && `
    padding-left: 0;
    padding-right: 0;
  `}

  > div {
    padding: 12px 18px;
  }
`;

export const DuplicateButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-bottom: -15px;
  color: #fff;
  background-color: #0092ff;
`;
