import styled from 'styled-components';

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-bottom: 1px solid var(--color-cs-fill-grey-4);
  background-color: #fff;
  z-index: 2;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 64px;
  min-width: 64px;
  padding: 18px;
  height: 64px;
  border-right: 1px solid #EAEDF3;
  transition: all .2s;

  ${({ expand }) => expand && `
    padding: 0 18px;
    width: 242px;
    min-width: 242px;
  `}
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 30px 0 12px;
  justify-content: space-between;
  height: 100%;
  transition: width .2s;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: 10px;
`;
