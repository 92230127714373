import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  .section-heading {
    opacity: .6;
    transition: opacity .2s;
  }

  &:hover {
    .section-heading {
      opacity: 1;
    }
  }
`;

export const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--color-cs-fill-grey-3);
  font-size: 14px;
  padding-left: 6px;

  > div > span {
    font-style: italic;
  }
`

export const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  cursor: pointer;

  > svg {
    transition: transform .2s;
  }

  ${({ isOpen }) => isOpen && `
    > svg {
      transform: rotate(90deg);
    }
  `}

  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;

    > svg {
      opacity: .2;
    }
  `}
`;
