import React from 'react';
import { withContext } from '@context';
import { Container, Content } from './styles';
import Menu from './Menu';

const Sidebar = ({ theme, defaultMenu, children }) => {
  return (
    <Container expand={theme.sidebarExpanded}>
      <Content>
        {defaultMenu ? <Menu expand={theme.sidebarExpanded} /> : children}
      </Content>
    </Container>
  );
}

export default withContext(['theme'])(Sidebar);
