import styled from 'styled-components';

export const Layout = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  `,
  Main: styled.div`
    position: relative;
    flex: 1;
    display: flex;
    overflow: hidden;
    transition: width .2s;
    z-index: 1;
  `,
  ContentHolder: styled.div`
    flex: 1;
    display: flex;
    position: relative;
  `,
  Content: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: 800px;
  `,
};
