import React, { memo } from "react";
import _ from 'lodash';
import Item from './index';
import { Group, GroupHolder } from './styles';


const SubItems = ({
  data,
  isOpen,
  isGlobalField,
}) => {
  if (!data.subItems) return null;
  const isRepeatble = data.settings.some((f) => f.label === 'repeatable' && f.value);

  return (
    <GroupHolder
      isInGroup
      isOpen={isOpen}
      isEmpty={!data.subItems || data.subItems.length === 0}
      isRepeatble={isRepeatble}
    >
      <Group
        isInGroup
        isEmpty={!data.subItems || data.subItems.length === 0}
      >
        {data.subItems &&
          data.subItems.map((asset) => (
            <Item
              key={asset.id}
              isGlobalField={isGlobalField}
              item={asset}
              isSectionOpen={true}
              isInGroup
            />
          ))
        }
      </Group>
    </GroupHolder>
  );
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(SubItems, areEqual);
