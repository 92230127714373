import _ from 'lodash';
import immer from 'immer';
import { transformTypeModel } from '@api/helpers';
import { removeKeys } from '@utils/helpers';

const initialState = {
  draft: {
    name: "",
    itemsCount: 0,
    data: [],
    __meta: {
      isNew: true,
      canSave: false,
    }
  },
  original: {
    name: "",
    itemsCount: 0,
    data: [],
    __meta: {
      isNew: true,
      canSave: false,
    }
  },
};

const contentTypeInit = (state, payload) => {
  return initialState;
};

const contentTypeSet = (state, payload) => {
  return { ...state, ...payload };
};

const contentTypeUpdate = (state, payload) => {
  payload = immer(payload, draftState => {
    const isNew = false;
    let canSave = true;

    if (areEqual(state, draftState)) {
      canSave = false;
    }

    draftState.draft.__meta.isNew = isNew;
    draftState.draft.__meta.canSave = canSave;
  });

  return {
    ...state,
    ...payload
  };
};

const contentTypeDelete = (state, payload) => {
  return { ...state, ...payload }
};

const contentTypeReset = (state, payload) => {
  return {
    ...transformTypeModel(state.original),
  }
};

const effects = {
  CONTENT_TYPE_INIT: contentTypeInit,
  CONTENT_TYPE_SET: contentTypeSet,
  CONTENT_TYPE_UPDATE: contentTypeUpdate,
  CONTENT_TYPE_DELETE: contentTypeDelete,
  CONTENT_TYPE_RESET: contentTypeReset,
};

export default {
  initialState,
  effects
};

const areEqual = (state, draftState) => {
  state = {
    name: state.original.name,
    data: state.original.data,
  };

  removeKeys(state);

  draftState = {
    name: draftState.draft.name,
    data: JSON.parse(JSON.stringify(draftState.draft.data)),
  };

  removeKeys(draftState)

  const isDataEqual = _.isEqual(state, draftState);
  return isDataEqual;
}
