import React, { memo } from "react";
import _ from 'lodash';
import { Droppable, Draggable } from "react-beautiful-dnd";
import Item from './index';

import { Group, GroupHolder } from './styles';


const SubItems = ({
  data,
  isOpen,
  isGlobalField,
  isDragStart,
  isDropDisabledForGroups,
  isDragging,
}) => {
  if (!data.subItems) return null;

  return (
    <Droppable
      droppableId={`${data.id}`}
      isDropDisabled={isDropDisabledForGroups || isGlobalField}

      renderClone={(provided, snapshot, rubric) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Item
              item={data.subItems[rubric.source.index]}
              isSectionOpen={true}
              isInGroup
              isDropDisabledForGroups={isDropDisabledForGroups}
              isDragStart={isDragStart}
              dragHandleProps={provided.dragHandleProps}
              isDragging={snapshot.isDragging}
            />
          </div>
        )
      }}
    >
      {(provided, snapshot) => {
        const isRepeatble = data.settings.some((f) => f.label === 'repeatable' && f.value);
        return (
          <GroupHolder
            ref={provided.innerRef}
            {...provided.droppableProps}
            isInGroup
            isDragging={isDragging}
            isDragStart={isGlobalField ? null : isDragStart}
            isOpen={isOpen}
            isEmpty={!data.subItems || data.subItems.length === 0}
            isRepeatble={isRepeatble}
          >
            <Group
              isInGroup
              isDragging={isDragging}
              isDragStart={isDragStart}
              isEmpty={!data.subItems || data.subItems.length === 0}
            >
              {data.subItems &&
                data.subItems.map((asset, subSubItemIndex) => (
                  <Draggable
                    draggableId={asset.id}
                    key={asset.id}
                    index={subSubItemIndex}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Item
                          isGlobalField={isGlobalField}
                          item={asset}
                          isSectionOpen={true}
                          isInGroup
                          isDropDisabledForGroups={isDropDisabledForGroups}
                          isDragStart={isDragStart}
                          dragHandleProps={provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              }
            </Group>
            {provided.placeholder}
          </GroupHolder>
        )
      }}
    </Droppable >
  );
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(SubItems, areEqual);
