import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: #4D7CFE;
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
  font-weight: 300;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;

  span {
    font-weight: 700;
  }
`;
