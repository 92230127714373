import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;

  > label {
    font-size: 10px;
    margin-bottom: 8px;
    letter-spacing: 1px;
    color: var(--color-cs-fill-grey-2);
    text-transform: uppercase;

    span {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .error-message {
    padding-top: 4px;
    color: #FE4D97;
    font-size: 12px;
    font-style: italic;
  }
`;

export const SwitchContainer = styled.div`
  > input {
    display: none;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label {
      background: #bada55;
    }

    &:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  > label {
    cursor: pointer;
    text-indent: -9999px;
    width: 34px;
    height: 18px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    &:active:after {
      width: 20px;
    }
  }
`;
