import React, { useEffect } from 'react';
import { withContext } from '@context';
import marked from 'marked';
import { PreviewContainer } from './styles';
// import Emoji from 'react-emoji-render';
// import emojify from 'emojify.js';
// import 'emojify.js/dist/css/basic/emojify.min.css';
import hljs from 'highlight.js';

const MarkedOutput = ({
  // playground,
  output,
}) => {
  marked.setOptions({
    highlight: (code) => {
      return hljs.highlightAuto(code).value;
    },
  });

  // componentDidMount & componentDidUpdate
  // useEffect(() => {
  //   emojis();
  // });

  // const emojis = () => {
  //   return emojify.run(document.getElementById('output'));
  // };

  return (
    <PreviewContainer>
      <div id="output" dangerouslySetInnerHTML={{ __html: marked(output) }} />
    </PreviewContainer>
  );
}

export default withContext(['playground'])(MarkedOutput);
