import React, { memo } from "react";
import _ from 'lodash';
import ColumnsRendered from './Columns';
import { Row } from './styles';

const Component = ({
  id,
  className = 'grid-row',
  style,
  row,
  index,
  contentEntrySettings,
  environments,
  locales,
  columns,
  loaded,
  onClick,
}) => {

  return (
    <Row
      id={id}
      className={className}
      style={style}
      loaded={loaded}
      onClick={onClick}
    >
      <ColumnsRendered
        row={row}
        index={index}
        contentEntrySettings={contentEntrySettings}
        environments={environments}
        locales={locales}
        columns={columns}
        loaded={loaded} />
    </Row>
  )
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Component, areEqual);
