import _ from 'lodash';

const initialState = {
  total: 0,
  list: null,
}

const contentTypesSet = (state, payload) => {
  return { ...state, ...payload }
};

const contentTypesAdd = (state, payload) => {
  const existingIndex = state.list.findIndex(f => f.draft.objectId === payload.draft.objectId);

  if (existingIndex !== -1) {
    state.list[existingIndex] = payload;
  } else {
    state.list.push(payload);
  }
  return { ...state };
};

const effects = {
  CONTENT_TYPES_SET: contentTypesSet,
  CONTENT_TYPES_ADD: contentTypesAdd,
};

export default {
  initialState,
  effects
};
