import React from 'react';
import { Container } from './styles';

const Spinner = ({
  color = '#4D7CFE',
  size = 30,
  stopAnimation,
}) => {

  return (
    <Container color={color} size={size} stopAnimation={stopAnimation} />
  );
};

export default Spinner;
