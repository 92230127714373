import React from 'react';
import API from '@api';
import { withContext } from '@context';
import { generateUID } from '@utils/helpers';
import { useInput, useSetState } from '@utils/hooks';
import { contentSections } from '@utils/constants';
import { withModal, Modal } from '@components/Modal';
import Button from '@components/Button';

import { Content } from './styles';

const ModalComponent = ({
  title,
  closeFn,
  dispatch,
  history,
  match,
}) => {
  const { params } = match;
  const isGlobalField = params.type === 'global-fields';

  const [state, setState] = useSetState({
    form: {
      entry: null,
    },
    isValid: false,
    isLoading: false,
  });

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });

    try {
      const draft = {
        name: state.form.entry,
        data: [{
          ...contentSections[0],
          label: 'Main',
          id: generateUID(6), // override the id
        }],
        isGlobalField,
      };

      const payload = await API.saveModel(draft);

      dispatch({
        type: 'CONTENT_TYPE_SET',
        payload,
      });

      dispatch({
        type: 'CONTENT_TYPES_ADD',
        payload,
      });

      setState({
        isLoading: false,
      });

      history.push(`/dashboard/${params.type}/${payload.original.objectId}`);

    } catch (e) {
      console.error(e.message);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: e.message,
      });
    }
  }

  const checkValidation = (s) => {
    let isValid = true;

    Object.keys(s.form).forEach((key) => {
      if (!s.form[key] || s.form[key] === '') {
        isValid = false;
      }
    });

    return isValid;
  }

  const [field, fieldInput] = useInput({
    label: 'Entry Name',
    type: "text",
    value: state.form.entry,
    onChange: (value) => {
      setState(state => {
        state.form.entry = value;
        state.isValid = checkValidation(state);
      });
    }
  });

  const handleModelChange = (value) => {
    setState(state => {
      state.form.modelObjectId = value;
      state.isValid = checkValidation(state);
    });
  }

  return (
    <Modal.Container size='xs'>
      <Modal.Header title={title} />
      <Modal.Content>
        <Content>
          {fieldInput}
        </Content>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: 'grey',
            size: 'md',
            borderRadius: '4px',
            minWidth: '100px',
          }}
        >Cancel</Button>
        <Button
          onClick={onSave}
          theme={{
            color: 'blue',
            size: 'md',
            borderRadius: '4px',
            minWidth: '100px',
            disabled: !state.isValid,
            isLoading: state.isLoading,
          }}
        >Create</Button>
      </Modal.Footer>
    </Modal.Container>
  );
}


export default withContext(['snackbar'])(withModal(ModalComponent));
