import React from 'react';
import { withContext } from '@context';
import Icon from '@components/Icon';
import { Container, Button } from './styles';

const Menu = ({
  expand,
}) => {

  const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    const path = pathname.split('/');
    return ['types', 'global-fields'].includes(path[2]);
  }

  return (
    <Container expand={expand}>
      <Button to={{ pathname: "/dashboard/entries" }} activeClassName="active">
        <Icon name="icon-align-left" size={[20]} />
        {expand && <span>Entries</span>}
      </Button>
      <Button to={{ pathname: "/dashboard/types" }} isActive={checkActive} activeClassName="active">
        <Icon name="icon-types" size={[20]} />
        {expand && <span>Content Types</span>}
      </Button>
      <Button to={{ pathname: "/dashboard/assets" }} activeClassName="active">
        <Icon name="icon-assets" size={[20]} />
        {expand && <span>Assets</span>}
      </Button>
      {/* <Button to={{ pathname: "/dashboard/playground", search }} activeClassName="active">
        <Icon name="icon-assets" size={[20]} />
        {expand && <span>playground</span>}
      </Button> */}
    </Container>
  );
}

export default withContext(['contentLocales'])(Menu);
