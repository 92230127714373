import React, { useEffect, useRef } from 'react';
import { withContext } from '@context';
import API from '@api';
import { useSetState, useCancellablePromise } from '@utils/hooks';
import Grid from '@components/Grid';
import Create from './create';

import { Toolbar, ContentHolder, Content } from './styles';

const List = ({
  dispatch,
  history,
  contentEntrySettings,
  contentEnvironments,
  contentLocales,
}) => {
  const refContainer = useRef();

  const [state, setState] = useSetState({
    showLoading: true,
    dimensions: null,
  });

  useEffect(() => {
    const { current } = refContainer;

    setState(state => {
      state.showLoading = true;
      state.dimensions = {
        width: current.offsetWidth,
        height: current.offsetHeight
      };
    });

    setTimeout(() => {
      setState(state => {
        state.showLoading = false;
      });
    }, 0);
  }, [contentEntrySettings]);

  const onRowClick = (row) => {
    let { locales } = contentEntrySettings;

    if (locales.length > 1) {
      locales = [contentLocales.find((f => f.isDefault))]
    }

    dispatch({
      type: 'CONTENT_ENTRY_SETTINGS_SET',
      payload: {
        locales,
      }
    });

    history.push(`/dashboard/entries/${row.objectId}`);
  }

  const columns = ['name', 'apiId', 'updatedAt'];
  const { cancellablePromise } = useCancellablePromise();
  const fetchData = async ({ afterDate } = {}) => {
    try {
      const call = API.getContents({
        afterDate,
        limit: 60,
      });

      const data = await cancellablePromise(call);

      const { total = 0, list = [] } = data;
      const picked = list.map(o => (({
        objectId, name, apiId, publishedStatus, versionsCount, createdAt
      }) => ({
        objectId, name, apiId, publishedStatus, versionsCount, createdAt
      }))(o.draft));

      return {
        total,
        list: picked,
      }
    } catch (e) {
      console.error(e.message);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: e.message,
      });
    }
  };

  return (
    <>
      <Toolbar>
        <span />
        <Create label="New Entry" />
      </Toolbar>
      <ContentHolder>
        <Content ref={refContainer}>
          {
            (!state.showLoading && state.dimensions) && (
              <Grid
                dimensions={state.dimensions}
                fetchData={fetchData}
                columns={columns}
                onRowClick={onRowClick}
                contentEntrySettings={contentEntrySettings}
                environments={contentEnvironments}
                locales={contentLocales}
                gridHeaderItems={['Name', 'Api Id', 'Last Update']}
                gridTemplateColumns="160px 1fr 1fr"
              />
            )
          }
        </Content>
      </ContentHolder>
    </>
  );
}

export default withContext(['snackbar', 'contentEntrySettings', 'contentEnvironments', 'contentLocales'])(List);
