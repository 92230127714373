import styled from 'styled-components';

export const CreateButton = styled.div`
  display: flex;
  align-items: center;
  color: #4D7CFE;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    svg {
      fill: #4D7CFE;
    }
  }

  svg {
    fill: #4D7CFE;
    margin-right: 4px;
  }
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 320px;
`;
