import React from 'react';
import { useInput } from '@utils/hooks';

const SectionSidebar = ({
  data,
  isModified,
  isNew,
  handleItemIdChange,
  handleItemLabelChange,
}) => {
  const renderItemLabel = () => {
    const [field, fieldInput] = useInput({
      label: 'section name',
      type: "text",
      value: data.label,
      placeholder: 'section name',
      isRequired: true,
      isValid: !isModified || data.label !== '',
      validationErrorMessage: 'cannot be empty',
      onChange: (value) => {
        handleItemLabelChange(value)
      }
    });

    return (
      <div key="label">
        {fieldInput}
      </div>
    )
  }

  const renderItemId = () => {
    const [field, fieldInput] = useInput({
      label: 'api id',
      type: "text",
      value: isNew ? '' : data.apiId,
      placeholder: 'api id',
      isRequired: true,
      isValid: !isModified || data.apiId !== '',
      validationErrorMessage: 'cannot be empty',
      onChange: (value) => {
        handleItemIdChange(value)
      }
    });

    return (
      <div key="apiId">
        {fieldInput}
      </div>
    )
  }

  return (
    <div>
      {renderItemLabel()}
      {renderItemId()}
    </div>
  );
};

export default SectionSidebar;
