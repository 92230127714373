const initialState = {
  value: ""
};

const playgroundSet = (state, payload) => {
  return payload
};

const playgroundSnackbar = (state, payload) => {
  return {
    value: ""
  }
};

const effects = {
  PLAYGROUND_SET: playgroundSet,
  PLAYGROUND_REMOVE: playgroundSnackbar,
};

export default {
  initialState,
  effects
};
