import React, { memo } from "react";
import _ from 'lodash';
import styled from "styled-components";
import RenderDate from './renderDate';
import Tooltip from '@components/Tooltip';
import { MdWarning } from "react-icons/md";
import { Environments, VersionsCount, ToolTipHolder, Locales, Loading } from './styles';

const Cell = (props) => {
  const {
    row,
    contentEntrySettings,
    environments,
    locales,
    field,
    loaded
  } = props;

  const render = () => {
    if (!loaded || !row) {
      return <Loading />
    }

    switch (field) {
      case 'updatedAt':
        return <RenderDate date={row[field]} />
      // case 'versionsCount':
      //   const currentLocaleObjectId = contentEntrySettings.locales[0].objectId;
      //   const toolTipInfo = environments.map(env => {
      //     const c = (row.publishedStatus || []).find(f => f[env.objectId] && f[env.objectId][currentLocaleObjectId]);
      //     if (c) {
      //       const environment = env.name;
      //       const version = c[env.objectId][currentLocaleObjectId][0].version;
      //       return (
      //         <div key={env.objectId}>
      //           {environment} {row.versionsCount > version ? `is ${row.versionsCount - version} version(s) behind` : 'is up to date'}
      //         </div>
      //       )
      //     }
      //   }).filter(f => f);

      //   const Overlay = ({ style, ...rest }) => {
      //     return (
      //       <ToolTipHolder {...rest} style={{ ...style, zIndex: 9999 }}>
      //         {toolTipInfo}
      //       </ToolTipHolder>
      //     );
      //   }

      //   const overlay = <Overlay>yep</Overlay>
      //   return (
      //     <Tooltip placement="left" arrowProps={{ size: 10 }} overlay={overlay} triggers="hover">
      //       <VersionsCount>{row[field]}</VersionsCount>
      //     </Tooltip>
      //   );
      case 'environments':
        return <div>fix me</div>
      // return (
      //   <Environments>
      //     {
      //       contentEntrySettings.environments.map(env => {
      //         const current = row[field].find(f => f.objectId === env.objectId)
      //         const check = () => {
      //           if (!current) {
      //             return <MdWarning style={{ fontSize: 14 }} />
      //           }

      //           return null;
      //         }

      //         return (
      //           <div key={env.objectId}>{env.name} {check()}</div>
      //         )
      //       })
      //     }
      //   </Environments>
      // );
      // case 'localesForEnvironments':
      //   const currentEnvironment = contentEntrySettings.environments[0];
      //   const content = contentEntrySettings.locales.map(l => {
      //     const exist = row.localesForEnvironments.some(s => {
      //       const env = Object.keys(s)[0];
      //       return env === currentEnvironment.objectId && s[env] === l.objectId
      //     });

      //     if (exist) {
      //       return (
      //         <div key={l.objectId}>{l.name}</div>
      //       )
      //     } else {
      //       const defaultLocale = locales.find(f => f.isDefault);
      //       return (
      //         <div key={defaultLocale.objectId} className="fallback">({defaultLocale.name})</div>
      //       )
      //     }
      //   });

      //   return (
      //     <Locales>{content}</Locales>
      //   );
      default:
        return row[field];
    }
  }

  return (
    <Container>
      {render()}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  padding: 0 20px;
  height: 100%;
`;

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Cell, areEqual);
