import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import immer from 'immer';
import { withContext } from '@context';
import { prepareObjectKey, generateUID } from '@utils/helpers';
import Icon from '@components/Icon';
import ConfigSidebar from '../shared/configSidebar';
import HeadingConfigMenu from '../shared/headingConfigMenu';
import SubItems from "./subItems";
import SectionSidebar from './sidebar';
import {
  findPath,
  getByPath,
  duplicateByPath,
  deleteByPath,
  updateByPath,
} from "../../utils";

import { Item, ItemContainer, TypeIcon, CollapseIcon } from './styles';

const ItemComponent = ({
  isInGroup,
  isGlobalField,

  item,
  isSectionOpen,
  dragHandleProps,
  isDragStart,

  isDropDisabledForGroups,
  isDragging,
  dispatch,
  contentType: {
    draft: contentTypeDraft,
  },
  contentGlobalFields,
}) => {
  const data = item;

  if (!data) return null;

  const { isNew, isOpen } = data.__meta;
  const isCustomKey = data.apiId !== prepareObjectKey(data.label);
  const isDisabled = !data.subItems || data.subItems.length === 0;

  const init = {
    data,
    isModified: false,
    isNew,
    isOpen,
    isCustomKey,
    isDisabled,
    isSelected: isNew,
  };

  const [state, setState] = useState(init);
  const [initialState, setInitialState] = useState();
  const [showSettings, setShowSettings] = useState(isNew);

  useEffect(() => {
    const data = item;
    const itemPath = findPath(contentTypeDraft.data, data.id);
    let itemRootItemsApiIds;

    if (itemPath) {
      itemPath.pop();
      const itemRootPath = itemPath.slice(0, -1);
      const itemRootItems = getByPath(contentTypeDraft.data, itemRootPath);
      itemRootItemsApiIds = itemRootItems.map((item) => {
        // exclude self apiId (needed for checking duplicates)
        if (data.id === item.id) {
          return null
        }
        return item.apiId;
      }).filter(f => f);
    }


    const { isNew, isOpen } = data.__meta;
    const isCustomKey = data.apiId !== prepareObjectKey(data.label);
    const isDisabled = !data.subItems || data.subItems.length === 0;

    const init = {
      data,
      itemPath,
      itemRootItemsApiIds,
      isModified: false,
      isNew,
      isOpen,
      isCustomKey,
      isDisabled,
      isSelected: isNew,
    };

    setInitialState(init);
    setState(init);
  }, [item, isSectionOpen]);


  const checkForDuplicates = (value) => {
    // fixme
    const uniq = [...state.itemRootItemsApiIds, prepareObjectKey(value)]
      .map((apiId) => {
        if (apiId !== "") {
          return {
            count: 1,
            apiId,
          }
        }
      })
      .filter(f => f)
      .reduce((a, b) => {
        a[b.apiId] = (a[b.apiId] || 0) + b.count
        return a
      }, {})

    const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
    return duplicates.length !== 0
  }

  const handleItemApiIdChange = (value) => {
    const payload = immer(state, draftState => {
      draftState.data.apiId = prepareObjectKey(value);
      draftState.isModified = true;
      draftState.isCustomKey = value !== '';
      draftState.hasErrors = checkForDuplicates(value);
    });

    setState(payload);
  }

  const handleItemLabelChange = (value) => {
    const payload = immer(state, draftState => {
      if (!draftState.isCustomKey || draftState.isNew) {
        draftState.data.apiId = prepareObjectKey(value);
        draftState.isNew = false;
        draftState.isCustomKey = false;
      }

      draftState.data.label = value;
      draftState.isModified = true;
      draftState.hasErrors = checkForDuplicates(value);
    });

    setState(payload);
  }

  const handleItemSettingsChange = (index, key, value) => {
    const payload = immer(state, draftState => {
      draftState.data.settings[index].value = value;
      draftState.isModified = true;
    });

    setState(payload);
  }

  const handleGlobalFieldChange = (key) => {
    const selected = contentGlobalFields.list.filter(f => f.draft.objectId === key)[0];
    const subItems = selected.draft.data.length > 0 ? selected.draft.data[0].subItems : [];

    const payload = immer(state, draftState => {
      draftState.data._id = selected.draft.objectId;
      draftState.data.subItems = subItems;
      draftState.isModified = true;
    });

    setState(payload);
  }

  const checkValidation = () => {
    let isValid = !state.hasErrors && state.data.apiId !== '' && state.isModified;

    [...state.data.__meta.required].forEach((key) => {
      if (isValid && state.data.settings[key] === '') {
        isValid = false;
      }
    });

    return isValid;
  }

  const handleDuplicateChild = () => {
    const draft = immer(contentTypeDraft, draftState => {
      const newItemDraft = getByPath(draftState.data, state.itemPath);

      const newItem = immer(newItemDraft, dState => {
        dState.id = generateUID(6);
        dState.label = `${state.data.label} (copy)`;
      });

      const newPath = [...state.itemPath];
      newPath.pop();

      draftState.data = duplicateByPath(draftState.data, newPath, newItem);
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });
  }

  const handleDelete = () => {
    const draft = immer(contentTypeDraft, draftState => {
      draftState.data = deleteByPath(draftState.data, state.itemPath);
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });

    dispatch({
      type: 'SNACKBAR_ADD',
      payload: `<b>${state.data.label}</b> was deleted!`
    });
  }

  const handleCancel = async () => {
    await setShowSettings(false);
    await setState(initialState);

    const draft = immer(contentTypeDraft, draftState => {
      const draft = immer(state.data, draftState => {
        draftState.__meta.isNew = false;
      });

      draftState.data = updateByPath(draftState.data, state.itemPath, draft);

      return draftState;
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });
  }

  const handleConfirm = async () => {
    const draft = immer(contentTypeDraft, draftState => {
      const draft = immer(state.data, draftState => {
        draftState.__meta.isNew = false;
      });

      draftState.data = updateByPath(draftState.data, state.itemPath, draft);

      return draftState;
    });

    setShowSettings(false);

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });
  }

  const toggleSectionHandler = (e) => {
    e.stopPropagation();
    const draft = immer(contentTypeDraft, draftState => {
      const draft = immer(state.data, draftState => {
        draftState.__meta.isOpen = !draftState.__meta.isOpen;
      });

      draftState.data = updateByPath(draftState.data, state.itemPath, draft);

      return draftState;
    });

    dispatch({
      type: 'CONTENT_TYPE_UPDATE',
      payload: {
        draft
      }
    });
  }

  const { apiId, type } = state.data;
  const isGroup = type === 'group' || type === 'globalField';
  const isRequired = state.data.settings.some((f) => f.type === 'required' && f.value);
  const placeholder = (state.data.settings || []).find((f) => f.type === 'placeholder') || {};
  const isRepeatble = state.data.settings.some((f) => f.type === 'repeatable' && f.value);
  const iconName = isRepeatble ? 'icon-repeat' : state.data.__meta.icon;
  const myDragHandleProps = isInGroup && isGroup ? {} : dragHandleProps;

  const isThisAglobalField = state.data.type === 'globalField';
  const isThisAglobalFieldChild = state.data.type === 'globalField' || isGlobalField;

  return (
    <>
      <Item.Holder
        isInGroup={isInGroup}
        isOpen={state.isOpen}
        isSectionOpen={isSectionOpen}
        isDragging={isDragging}
        isDragStart={isDragStart}
        isThisAglobalFieldChild={isThisAglobalFieldChild}
        isSelected={state.isSelected}
      >
        <ItemContainer
          isDragging={isDragging}
          isDragStart={isDragStart}
          isInGroup={isInGroup}
          isGroup={isGroup}
          isEmpty={!state.data.subItems || state.data.subItems.length === 0}
          isGlobalField={isGlobalField}
          isThisAglobalField={isThisAglobalField}
          className={`${isThisAglobalFieldChild && 'globalField'}`}
        >
          <span {...dragHandleProps} />
          <Item.Header.Container {...myDragHandleProps} onClick={() => setShowSettings(true)}>
            <Item.Header.Top>
              <Item.Header.Title>
                <TypeIcon isGlobalField={isGlobalField} isSelected={state.isSelected}>
                  <Icon name={iconName} fill="#8091a5" size={[10]} />
                </TypeIcon>
                {
                  isGroup && (
                    <CollapseIcon onClick={toggleSectionHandler} isOpen={state.isOpen} isDisabled={state.isDisabled}>
                      <Icon name="icon-arrow-right" fill="var(--color-cs-fill-grey-3)" size={[8]} />
                    </CollapseIcon>
                  )
                }
                {isRequired && <span>*</span>}
                {state.data.label || state.data.__meta.label}
              </Item.Header.Title>
              {
                isGlobalField ? null : (
                  <HeadingConfigMenu
                    handleDelete={handleDelete}
                    handleSettings={() => {
                      setState({
                        ...state,
                        isSelected: true,
                      });
                      setShowSettings(true)
                    }}
                    dragHandleProps={dragHandleProps}
                    // fix me ... we should count items somehow and myltiply thmem by 20px for the menu
                    isSection={state.data.type === 'globalField'}
                    itemsCount={isThisAglobalField ? 2 : 3}
                    handleDuplicateChild={isGlobalField || isThisAglobalField ? null : handleDuplicateChild}
                  />
                )
              }
            </Item.Header.Top>
            <Item.Header.SubTitle>
              <span>
                API ID: {apiId}
              </span>
            </Item.Header.SubTitle>
            {
              !isGroup && (
                <Item.ContnentContainer>
                  <Item.Content>
                    <h3>{placeholder.value || '...'}</h3>
                  </Item.Content>
                </Item.ContnentContainer>
              )
            }
          </Item.Header.Container>

          <SubItems
            data={state.data}
            isOpen={state.isOpen}
            isGlobalField={isThisAglobalFieldChild} // this is important for recursion
            isDragStart={isDragStart}
            isDropDisabledForGroups={isDropDisabledForGroups}
            isDragging={isDragging}
          />
        </ItemContainer>
      </Item.Holder>

      <ConfigSidebar
        type={state.data.label}
        visible={showSettings}
        handleConfirm={handleConfirm}
        isModified={state.isModified}
        isValid={checkValidation()}
        closeFn={handleCancel}
      >
        <SectionSidebar
          item={state}
          hasErrors={state.hasErrors}
          isGlobalField={isThisAglobalField}
          handleItemApiIdChange={handleItemApiIdChange}
          handleItemLabelChange={handleItemLabelChange}
          handleItemSettingsChange={handleItemSettingsChange}
          contentGlobalFields={contentGlobalFields}
          handleGlobalFieldChange={handleGlobalFieldChange}
        />
      </ConfigSidebar>
    </>
  )
};

export default withContext(['contentGlobalFields', 'contentType'])(ItemComponent);
