import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import immer from 'immer';
import { withContext } from '@context';
import Icon from '@components/Icon';
import SubItems from "./subItems";
import Types from "../types";

import {
  findPath,
  getByPath,
  duplicateByPath,
  deleteByPath,
  updateByPath,
} from "../../utils";

import { Item, ItemContainer, TypeIcon, CollapseIcon, DuplicateButton } from './styles';

const ItemComponent = ({
  isInGroup,
  isGlobalField,

  item,
  isSectionOpen,

  dispatch,
  contentEntry: {
    draft: contentEntryDraft,
  },
}) => {
  const data = item;

  if (!data) return null;

  const { isNew, isOpen } = data.__meta;
  const isDisabled = !data.subItems || data.subItems.length === 0;

  const init = {
    data,
    isNew,
    isOpen,
    isDisabled,
  };

  const [state, setState] = useState(init);

  useEffect(() => {
    const data = item;
    const itemPath = findPath(contentEntryDraft.data, data.id);
    let itemRootItemsApiIds;

    if (itemPath) {
      itemPath.pop();
      const itemRootPath = itemPath.slice(0, -1);
      const itemRootItems = getByPath(contentEntryDraft.data, itemRootPath);
      itemRootItemsApiIds = itemRootItems.map((item) => {
        // exclude self apiId (needed for checking duplicates)
        if (data.id === item.id) {
          return null
        }
        return item.apiId;
      }).filter(f => f);
    }


    const { isNew, isOpen } = data.__meta;
    const isDisabled = !data.subItems || data.subItems.length === 0;

    const init = {
      data,
      itemPath,
      itemRootItemsApiIds,
      isNew,
      isOpen,
      isDisabled,
    };

    setState(init);
  }, [item, isSectionOpen]);

  const toggleSectionHandler = (e) => {
    e.stopPropagation();
    const draft = immer(contentEntryDraft, draftState => {
      const draft = immer(state.data, draftState => {
        draftState.__meta.isOpen = !draftState.__meta.isOpen;
      });

      draftState.data = updateByPath(draftState.data, state.itemPath, draft);

      return draftState;
    });

    dispatch({
      type: 'CONTENT_ENTRY_UPDATE',
      payload: {
        draft
      }
    });
  }

  const handleChange = (value) => {
    const draft = immer(contentEntryDraft, draftState => {
      const draft = immer(state.data, draftState => {
        draftState.value = value;
      });

      draftState.data = updateByPath(draftState.data, state.itemPath, draft);

      return draftState;
    });


    dispatch({
      type: 'CONTENT_ENTRY_UPDATE',
      payload: {
        draft
      }
    });
  }

  const { apiId, type } = state.data;
  const isGroup = type === 'group' || type === 'globalField';
  const isRequired = state.data.settings.some((f) => f.label === 'required' && f.value);
  const isRepeatble = state.data.settings.some((f) => f.label === 'repeatable' && f.value);
  const iconName = isRepeatble ? 'icon-repeat' : state.data.__meta.icon;

  const isThisAglobalField = state.data.type === 'globalField';
  const isThisAglobalFieldChild = state.data.type === 'globalField' || isGlobalField;

  return (
    <>
      <Item.Holder
        isInGroup={isInGroup}
        isOpen={state.isOpen}
        isSectionOpen={isSectionOpen}
        isThisAglobalFieldChild={isThisAglobalFieldChild}
        isSelected={state.isSelected}
      >
        <ItemContainer
          isInGroup={isInGroup}
          isGroup={isGroup}
          isEmpty={!state.data.subItems || state.data.subItems.length === 0}
          isGlobalField={isGlobalField}
          isThisAglobalField={isThisAglobalField}
          className={`${isThisAglobalFieldChild && 'globalField'}`}
        >
          <Item.Header.Container>
            <Item.Header.Top>
              <Item.Header.Title>
                <TypeIcon isGlobalField={isGlobalField} isSelected={state.isSelected}>
                  <Icon name={iconName} fill="#8091a5" size={[10]} />
                </TypeIcon>
                {
                  isGroup && (
                    <CollapseIcon onClick={toggleSectionHandler} isOpen={state.isOpen} isDisabled={state.isDisabled}>
                      <Icon name="icon-arrow-right" fill="var(--color-cs-fill-grey-3)" size={[8]} />
                    </CollapseIcon>
                  )
                }
                {isRequired && <span>*</span>}
                {state.data.label || state.data.__meta.label}
              </Item.Header.Title>
            </Item.Header.Top>
            <Item.Header.SubTitle>
              <span>
                API ID: {apiId}
              </span>
            </Item.Header.SubTitle>
            {
              !isGroup && (
                <Item.ContnentContainer>
                  <Item.Content>
                    <Types data={state.data} handleChange={handleChange} />
                  </Item.Content>
                </Item.ContnentContainer>
              )
            }
          </Item.Header.Container>

          <SubItems
            data={state.data}
            isOpen={state.isOpen}
            isGlobalField={isThisAglobalFieldChild} // this is important for recursion
          />
        </ItemContainer>
      </Item.Holder>
      {/* {isRepeatble && (
        <DuplicateButton>bla</DuplicateButton>
      )} */}
    </>
  )
};

export default withContext(['contentEntry'])(ItemComponent);
