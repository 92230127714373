import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useDelayUnmount } from '@utils/hooks';
import styled, { keyframes, css } from 'styled-components';
import * as Modal from './components';

const rootExtras = document.getElementById('root-extras');

const withModal = (WrappedComponent = Container) => {
  return (props) => {
    const { closeFn, visible } = props;
    const [isMounted, setIsMounted] = useState(false);
    const shouldRenderChild = useDelayUnmount(isMounted, 200);

    useEffect(() => {
      setIsMounted(visible);
    }, [visible]);

    const modalComponent = (
      <WrapperComponent isMounted={isMounted}>
        <WrappedComponentHolder>
          <WrappedComponent {...props} />
        </WrappedComponentHolder>
        <KeyboardEventHandler
          isExclusive
          handleFocusableElements={true}
          handleKeys={['esc']}
          onKeyEvent={(key, e) => {
            closeFn();
          }} />
      </WrapperComponent>
    );

    return (
      shouldRenderChild && (
        ReactDOM.createPortal(
          modalComponent,
          rootExtras,
        )
      )
    )
  }
}

export {
  withModal,
  Modal,
};

const WrapperComponent = styled.div`
  position: fixed;
  left: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(34, 41, 51, .2);
  z-index: 9000;

  ${({ isMounted }) => css`
    animation: ${isMounted ? fadeIn : fadeOut} .2s forwards;
  `}
`;

const WrappedComponentHolder = styled.div`
  position: relative;
  height: 100vh;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
