import { withRouter } from 'react-router-dom';
import { connect } from 'simpply';
import { createProvider, createSystemStorage } from 'simpply';

import user from './user';
import theme from './theme';
import snackbar from './snackbar';
import contentEnvironments from './contentEnvironments';
import contentLocales from './contentLocales';
import contentType from './contentType';
import contentTypes from './contentTypes';
import contentEntry from './contentEntry';
import contentEntrySettings from './contentEntrySettings';
import contentEntries from './contentEntries';
import contentGlobalFields from './contentGlobalFields';
import playground from './playground';

const systemStorage = createSystemStorage({
  user,
  theme,
  snackbar,
  contentEnvironments,
  contentLocales,
  contentType,
  contentTypes,
  contentEntry,
  contentEntrySettings,
  contentEntries,
  contentGlobalFields,
  playground,
});

const AppProvider = createProvider(systemStorage, {
  logging: false
});

const withContext = props => Comp => {
  const mapStateToProps = state => {
    const obj = {};
    props.forEach((p) => {
      obj[p] = state[p];
    });

    return obj;
  };

  return connect(mapStateToProps)(withRouter(Comp));
};

export {
  AppProvider,
  withContext,
}
