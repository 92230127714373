import React from 'react';
import * as S from './styles';

const Container = ({ children, size }) => {
  return (
    <S.Container>
      <S.Holder size={size}>{children}</S.Holder>
    </S.Container>
  );
}

const Header = ({ title, description, children }) => {
  return (
    <S.Header.Container>
      { title && <S.Header.Title dangerouslySetInnerHTML={{ __html: title }} />}
      { description && <S.Header.Description dangerouslySetInnerHTML={{ __html: description }} />}
      {children && children}
    </S.Header.Container>
  );
}

const Content = ({ refEl, id, children }) => {
  return (
    <S.Content>
      <S.ContentHolder ref={refEl} id={id}>
        {children}
      </S.ContentHolder>
    </S.Content>
  );
}
const Footer = ({ children }) => {
  return (
    <S.Footer>
      {children}
    </S.Footer>
  );
}

export {
  Container,
  Header,
  Content,
  Footer,
}
