import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import storage from 'local-storage-fallback';
import queryString from 'query-string';
import { withContext } from '@context';
import API from '@api';
import SEO from '@components/SEO';
import Layout from '@components/Layout';
import Snackbar from '@components/Snackbar';
import { Login } from '@pages/auth';
import Notfound from '@pages/default';

const Component = ({
  user,
  location,
  dispatch,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user.token) {
      setLoading(false);
      return null;
    }

    const environments = async () => {
      try {
        const payload = await API.getEnvironments();

        dispatch({
          type: 'ENVIRONMENTS_SET',
          payload,
        });

        return payload;
      } catch (e) {
        console.error(e.message);
      }
    }

    const locales = async () => {
      try {
        const payload = await API.getLocales();

        dispatch({
          type: 'LOCALES_SET',
          payload,
        });

        return payload;
      } catch (e) {
        console.error(e.message);
      }
    }

    const models = async () => {
      try {
        const payload = await API.getModels();

        dispatch({
          type: 'CONTENT_TYPES_SET',
          payload,
        });

        return payload;
      } catch (e) {
        console.error(e.message);
      }
    };

    const globalFields = async () => {
      try {
        const payload = await API.getModels({ isGlobalField: true });

        dispatch({
          type: 'CONTENT_GLOBAL_FIELDS_SET',
          payload,
        });

        return payload;
      } catch (e) {
        console.error(e.message);

        dispatch({
          type: 'SNACKBAR_ADD',
          payload: e.message,
        });
      }
    };

    Promise.all([
      environments(),
      locales(),
      models(),
      globalFields(),
    ]).then(async (p) => {
      await transformQueryStringsToContext({
        contentEnvironments: p[0],
        contentLocales: p[1],
      });

      setLoading(false);
    }).catch((e) => {
      console.error(e.message)
    });
  }, []);

  const transformQueryStringsToContext = async ({
    contentEnvironments,
    contentLocales,
  }) => {
    const { search } = location;
    const searchParams = queryString.parse(search);

    const cs = storage.getItem('contentship');
    const contentship = cs !== "" ? JSON.parse(cs) : {};
    const entrySettings = contentship['config']['entrySettings'] || {};

    const payload = {};

    // Prepare Environments
    const localEnvironments = entrySettings.environments || [];
    let currentEnvironments = contentEnvironments;


    if (localEnvironments.length === 1) {
      currentEnvironments = localEnvironments;
    } else {
      currentEnvironments = [contentEnvironments.find(f => f.isDefault)];
    }

    if (searchParams.environment) {
      const findEnvironments = contentEnvironments.find(f => f.name === searchParams.environment);
      if (findEnvironments) {
        currentEnvironments = [findEnvironments];
      }
    }
    payload['environments'] = currentEnvironments;

    // Prepare Locales
    const localLocales = entrySettings.locales || [];
    let currentLocale = contentLocales;

    if (localLocales.length === 1) {
      currentLocale = localLocales;
    } else {
      currentLocale = [contentLocales.find(f => f.isDefault)];
    }

    if (searchParams.locale) {
      const findLocales = contentLocales.find(f => f.tag === searchParams.locale);
      if (findLocales) {
        currentLocale = [findLocales];
      }
    }
    payload['locales'] = currentLocale;

    if (search !== "") {
      window.history.replaceState(null, null, window.location.pathname);
    }

    dispatch({
      type: 'CONTENT_ENTRY_SETTINGS_SET',
      payload,
    });
  }

  return (
    <>
      <SEO />
      {
        !loading && (
          <Switch>
            <Redirect exact from="/" to='/dashboard' />
            <Route
              path="/dashboard"
              render={() => {
                return (
                  <>
                    <Route render={(props) => {
                      return user.token
                        ? <Layout {...props} />
                        : <Login {...props} />
                    }} />

                  </>
                )
              }}
            />
            <Route path="*" component={Notfound} />
          </Switch>
        )
      }
      <Snackbar />
    </>
  );
};

export default withContext(['user'])(Component);
