import React from 'react';
import Icon from '@components/Icon';

import { Item, ItemContainer, TypeIcon } from '../items/styles';

const Placeholder = ({ item }) => {
  return (
    <Item.Holder isSectionOpen={true} isPlaceholder>
      <ItemContainer isDragging={true} isPlaceholder>
        <Item.Header.Container>
          <Item.Header.Top>
            <Item.Header.Title>
              <TypeIcon>
                <Icon name={item.__meta.icon} fill="#8091a5" size={[10]} />
              </TypeIcon>
              {item.__meta.label}
            </Item.Header.Title>
          </Item.Header.Top>
          <Item.Header.SubTitle>{item.__meta.desc}</Item.Header.SubTitle>
        </Item.Header.Container>
      </ItemContainer>
    </Item.Holder>
  )
}

export default Placeholder;
