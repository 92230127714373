import storage from 'local-storage-fallback';

const cs = storage.getItem('contentship');
const contentship = cs !== "" ? JSON.parse(cs) : {};
const { config = {} } = contentship || {};
const initialState = {
  ...config.theme,
  modelInnerSidebarExpanded: false,
}

const themeUpdate = (state, payload) => {
  return { ...state, ...payload };
}

const effects = {
  THEME_UPDATE: themeUpdate,
};

export default {
  initialState,
  effects
};
