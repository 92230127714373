import storage from 'local-storage-fallback';

const cs = storage.getItem('contentship');
const contentship = cs !== "" && JSON.parse(cs);

let initialState = {};
if (contentship) {
  const { objectId, username, token } = contentship;
  initialState = {
    objectId,
    username,
    token,
  }
}

const userSet = async (_, payload) => payload;
const userUpdate = async (state, payload) => {
  return { ...state, ...payload };
};

const effects = {
  USER_SET: userSet,
  USER_UPDATE: userUpdate,
};

export default {
  initialState,
  effects
};
