import React, { useState } from 'react';
import Editor from './Editor';
import MarkedOutput from './MarkedOutput';

import { Container } from './styles';

const App = ({ value = "", onChange }) => {
  return (
    <Container>
      <Editor output={value} onChange={onChange} />
      {/* <MarkedOutput output={value} /> */}
    </Container>
  );
}

export default App;
