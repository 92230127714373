import React, { useState, useRef } from 'react';
import { withContext } from '@context';
import API from '@api';
import Button from '@components/Button';
import FloatingInput from '../components/FloatingInput';
import Icon from '@components/Icon';

import {
  Container,
  Holder,
  Box,
  Logo,
  LoadingAnimation,
} from '../styles';

const usernameRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
const passwordRegex = /[^ ]{6,40}$/;

const Component = ({ dispatch }) => {
  const refUsername = useRef();
  const refPassword = useRef();

  const [state, setState] = useState({
    username: '',
    password: '',
    btnIsDisabled: true,
  });

  const [showAnimation, showAnimationHandler] = useState(false);

  const onChange = () => {
    const username = (refUsername.current.value).toLowerCase();
    const password = refPassword.current.value;

    let btnIsDisabled = true
    if (usernameRegex.exec(username) && passwordRegex.exec(password)) {
      btnIsDisabled = false;
    }

    setState({
      username,
      password,
      btnIsDisabled,
    });
  }

  const handleLogin = async () => {
    showAnimationHandler(true);

    try {
      const { username, password } = state;
      const user = await API.auth({ username, password });

      await dispatch({
        type: 'USER_SET',
        payload: user,
      });

      location.reload();
    } catch (e) {
      console.error(e.message);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: e.message,
      });

      setTimeout(() => {
        showAnimationHandler(false);
      }, 1000);
    }
  }

  return (
    <Container>
      <Holder>
        <Box>
          <Logo>
            <Icon name="icon-folder" fill="#77b5f1" size={[60]} />
            <h1>Вход</h1>
          </Logo>
          <FloatingInput>
            <input
              ref={refUsername}
              id="email"
              name="email"
              type="email"
              placeholder="Е-мейл"
              value={state.username}
              pattern={usernameRegex.toString().slice(1, -1)}
              onChange={onChange}
            />
            <label htmlFor="email">Е-мейл:</label>
            <span className="icon"><Icon name="icon-mail" fill="#77b5f1" size={[20]} /></span>
          </FloatingInput>

          <FloatingInput>
            <input
              ref={refPassword}
              placeholder="Парола"
              type="password"
              name="password"
              id="password"
              value={state.password}
              pattern={passwordRegex.toString().slice(1, -1)}
              onChange={onChange}
            />
            <label htmlFor="password">Парола:</label>
            <span className="icon"><Icon name="icon-lock" fill="#77b5f1" size={[20]} /></span>
          </FloatingInput>

          <LoadingAnimation showAnimation={showAnimation}>
            <Button
              onClick={handleLogin}
              theme={{
                color: 'blue',
                size: 'md',
                borderRadius: '4px',
                disabled: state.btnIsDisabled,
              }}
            >Вход</Button>
            <div className="spinner" />
          </LoadingAnimation>
        </Box>
      </Holder>
    </Container>
  );
};

export default withContext(['user', 'theme'])(Component);
