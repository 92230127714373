import React from 'react';
import { Container } from './styles';

const Page = () => {
  return (
    <Container>Assets (TBD)</Container>
  );
}

export default Page;
