import styled, { keyframes, css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  ${({ size }) => size && css`
    width: ${size}px;
    height: ${size}px;
  `};

  border: 4px solid transparent;
  border-radius: 100%;

  ${({ color }) => color && css`
    border-color: ${color};
    border-top-color: ${lighten(.2, color)};
  `}

  ${() => css`
    animation: ${spinnerAnimation} .6s infinite linear;
  `}

  ${({ stopAnimation }) => stopAnimation && css`
    animation: none;
  `}
`;

const spinnerAnimation = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;
