import React from 'react';
import { withContext } from '@context';
import { StyledNavLink } from './styles';

const Component = ({
  location,
  theme,
  style
}) => {
  const render = () => {
    if (theme.sidebarExpanded) {
      return (
        <>
          Content<span>Ship</span>
        </>
      );
    }

    return (
      <>
        C<span>S</span>
      </>
    )
  }
  return (
    <StyledNavLink to="/" style={style}>
      {render()}
    </StyledNavLink>
  );
};


export default withContext(['theme'])(Component);
