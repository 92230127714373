import _ from 'lodash';
import immer from 'immer';
import { transformTypeModel } from '@api/helpers';
import { removeKeys } from '@utils/helpers';

const initialState = {
  list: null,
}

const contentGlobalFieldsSet = (state, payload) => {
  return { ...state, ...payload };
}

const contentGlobalFieldsAdd = (state, payload) => {
  const existingIndex = state.list.findIndex(f => f.draft.objectId === payload.draft.objectId);

  if (existingIndex !== -1) {
    state.list[existingIndex] = payload;
  } else {
    state.list.push(payload);
  }

  return { ...state };
};

const effects = {
  CONTENT_GLOBAL_FIELDS_SET: contentGlobalFieldsSet,
  CONTENT_GLOBAL_FIELDS_ADD: contentGlobalFieldsAdd,
};

export default {
  initialState,
  effects
};
