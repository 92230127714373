import React from 'react';
import { useInput } from '@utils/hooks';

const Input = ({
  value,
  placeholder,
  isRequired,
  onChange,
}) => {
  const [field, fieldInput] = useInput({
    type: "text",
    value,
    placeholder,
    isRequired,
    isValid: isRequired ? value !== '' : true,
    validationErrorMessage: 'cannot be empty',
    onChange,
  });

  return fieldInput;
};

export default Input;
