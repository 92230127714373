import React from 'react';
import { Route } from 'react-router-dom';
import Sidebar from '@components/Sidebar';
import toolbar from './toolbar';
import page from './page';


export default {
  toolbar: [
    <Route
      key='toolbar'
      render={({ match: { url } }) => {
        return (
          <>
            <Route exact path={`${url}/assets`} component={toolbar} />
          </>
        )}
      }
    />
  ],

  sidebar: [
    <Route
      key='sidebar'
      render={({ match: { url } }) => {
        return (
          <>
            <Route exact path={`${url}/assets`} component={() => <Sidebar defaultMenu />} />
          </>
        )}
      }
    />
  ],

  routes: [
    <Route
      key="routes"
      render={({ match: { url } }) => {
        return (
          <>
            <Route exact path={`${url}/assets`} component={page} />
          </>
        )}
      }
    />
  ],
};
