import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  *:focus,
  textarea:focus,
  input:focus,
  button:focus {
    outline: none;
  }

  textarea,
  input,
  button {
    &::-webkit-input-placeholder {
      font-style: italic;
      font-weight: 300;
      color: ${lighten(.2, '#455867')};
    }
    &:-moz-placeholder {
      font-style: italic;
      font-weight: 300;
      color: ${lighten(.2, '#455867')};
    }
    &::-moz-placeholder {
      font-style: italic;
      font-weight: 300;
      color: ${lighten(.2, '#455867')};
    }
    &:-ms-input-placeholder {
      font-style: italic;
      font-weight: 300;
      color: ${lighten(.2, '#455867')};
    }

    &:focus {
      &::-webkit-input-placeholder { color: transparent; }
      &:-moz-placeholder { color: transparent; }
      &::-moz-placeholder { color: transparent; }
      &:-ms-input-placeholder { color: transparent; }
    }
  }

  html, body {
    padding: 0;
    margin: 0;
    color: #252631;
    height: 100%;
    font-family: "Ubuntu", sans-serif;
    /* font-family: 'Rubik', sans-serif; */

    --color-cs-bg: #F8FAFB;
    --color-cs-fill-grey-0: #F2F4F6;
    --color-cs-fill-grey-1: #F8FAFB;
    --color-cs-fill-grey-2: #98A9BC;
    --color-cs-fill-grey-3: #778CA2;
    --color-cs-fill-grey-4: #EAEDF3;
    --color-primary: #2186EB;
    --color-secondary: #F7C948;

    --color-cs-draft-fill: #FFF9EE;
    --color-cs-draft-fill-hover: #F8F4EA;
    --color-cs-draft-color: #FFAB2B;

    --color-cs-live-fill: #F4FCEF;
    --color-cs-live-fill-hover: #EDF7EB;
    --color-cs-live-color: #6DD230;

    background-color: var(--color-cs-bg);
  }

  #svgSprite {
    display: none;
    pointer-events: none;
  }

  #root {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
  }

  #root-extras {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
`;
