import _ from 'lodash';

export const findPath = (obj, item) => {
  for (var key in obj) {
    if (obj[key] && typeof obj[key] === "object") {
      var result = findPath(obj[key], item);
      if (result) {
        result.unshift(key);
        return result;
      }
    } else if (key === 'id' && obj[key] === item) {
      return [key];
    }
  }
}

export const getByPath = (obj, path, separator = '.') => {
  var properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const reorderByPath = (obj, path, startIndex, endIndex) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      if (acc[key]) {
        acc[key] = reorder(
          acc[key],
          startIndex,
          endIndex,
        );
      }
      return true;
    }
    return acc[key];
  }, _obj);

  return _obj;
}

export const deleteByPath = (obj, path) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc.splice(key, 1);
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

export const duplicateByPath = (obj, path, newItem) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc.subItems.splice(index + 1, 0, Object.assign(Object.assign({}, newItem)));
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

export const updateByPath = (obj, path, props) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = { ...acc[key], ...props };
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

const addByPath = (obj, path, draggedItem, destinationIndex) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path;

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key].subItems.splice(destinationIndex, 0, draggedItem);
      return true;
    }

    return acc[key];
  }, _obj);

  return _obj;
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
