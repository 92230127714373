import React, { memo } from 'react';
import _ from 'lodash';
import { Container, Menu, Settings } from './styles';

const headingConfigMenu = ({
  itemsCount = 3,
  isSection,
  handleDelete,
  handleSettings,
  handleDuplicateChild,
  dragHandleProps,
  className,
}) => {
  return (
    <Container className={className} itemsCount={itemsCount}>
      <span {...dragHandleProps} />
      <Menu>
        {handleDelete && (
          <div className="action la la-trash" onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }} />
        )}

        {handleDuplicateChild && (
          <div className="action la la-copy" onClick={(e) => {
            e.stopPropagation();
            handleDuplicateChild()
          }} />
        )}

        {handleSettings && (
          <div className="action la la-cog" onClick={(e) => {
            e.stopPropagation()
            handleSettings(true)
          }} />
        )}
      </Menu>
      <Settings isSection={isSection}>
        <div className="action la la-cog" />
      </Settings>
    </Container>
  )
};

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(headingConfigMenu, areEqual);
