import React, { useState, useEffect, useRef } from 'react';
import Button from '@components/Button';
import { withModal } from '@components/Modal';
import { useOutsideClick, useDelayUnmount } from '@utils/hooks';

import { Container, Header, Content, Footer } from './styles';

const configSidebar = ({
  children,
  visible,
  isValid,
  closeFn,
  handleConfirm,
  type,
}) => {
  const refContainer = useRef();

  const [isMounted, setIsMounted] = useState(false);
  const shouldRenderChild = useDelayUnmount(isMounted, 150);

  useEffect(() => {
    setIsMounted(visible);
  }, [visible]);

  useOutsideClick(refContainer, () => {
    closeFn();
  });

  return (
    shouldRenderChild && (
      <Container ref={refContainer} isMounted={isMounted}>
        <Header>
          <span>Edit</span>
          {type}
        </Header>
        <Content>
          {children}
        </Content>
        <Footer>
          <Button
            onClick={closeFn}
            theme={{
              color: 'grey',
              size: 'md',
              borderRadius: '4px',
              minWidth: '100px',
            }}
          >Cancel</Button>
          <Button
            onClick={handleConfirm}
            theme={{
              color: 'blue',
              size: 'md',
              borderRadius: '4px',
              minWidth: '100px',
              disabled: !isValid,
            }}
          >OK</Button>
        </Footer>
      </Container>
    )
  )
};

export default withModal(configSidebar);
