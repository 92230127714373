import React, { Suspense } from 'react';

const withSuspense = (Component) => {
  return props => (
    <Suspense fallback={renderLoader()}>
      <Component {...props} />
    </Suspense>
  );
}

const renderLoader = () => {
  return null;
}

export default withSuspense;
