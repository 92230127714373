import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Isvg from 'react-inlinesvg';
import { AppProvider } from '@context';
import App from './app';
import { GlobalStyle } from '@styles/styles';
import svgSprite from './assets/img/sprite.svg';

const UserConfirmation = (message, callback) => {
  const isConfirmed = confirm(message);

  if (isConfirmed == true) {
    callback(true);
  } else {
    callback(false);
  }
};

const Entry = () => {
  return (
    <>
      <GlobalStyle />
      <AppProvider>
        <Router getUserConfirmation={(message, callback) => {
          return UserConfirmation('Changes that you made may not be saved.', callback)
        }}>
          <App />
        </Router>
      </AppProvider>
      <div id="svgSprite">
        <Isvg src={svgSprite} uniquifyIDs={false} />
      </div>
    </>
  );
};

const rootElement = document.getElementById('root');

const render = (Component) => {
  ReactDOM.render(
    <Component />, rootElement
  )
}

if ('serviceWorker' in navigator && window.location.protocol === 'https:') {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./sw.js').then(reg => {
      if (reg.installing) {
        reg.installing.addEventListener('statechange', () => {
          var _a;
          if (((_a = reg.installing) === null || _a === void 0 ? void 0 : _a.state) === 'installed')
            console.log('Installed service worker. Offline functionality now available!');
        });
      }
      reg.addEventListener('updatefound', () => {
        console.info('App update found!');
      });
    });
  });
}


render(Entry)

if (module.hot) {
  module.hot.accept(Entry, () => {
    render(Entry)
  })
}
