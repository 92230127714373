const initialState = {};

const addSnackbar = (state, payload) => {
  return { content: payload };
};

const removeSnackbar = (state, payload) => {
  return {}
};

const effects = {
  SNACKBAR_ADD: addSnackbar,
  SNACKBAR_REMOVE: removeSnackbar,
};

export default {
  initialState,
  effects
};
