const initialState = [];

const localesSet = (state, payload) => {
  return [...payload];
};

const effects = {
  LOCALES_SET: localesSet,
};

export default {
  initialState,
  effects
};
