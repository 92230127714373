import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { withContext } from '@context';
import { GlobalSnackbarStyle, Container, Content } from './styles';

const portal = document.createElement("div");
portal.classList.add('snackbar');
document.body.appendChild(portal);

const Snackbar = withContext(['snackbar'])(({ snackbar }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let timeout;

  const showFn = async () => {
    setIsMounted(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }

  const hideFn = (e) => {
    if (e) e.stopPropagation();

    setIsVisible(false);
    setTimeout(() => {
      setIsMounted(false);
    }, 400);
  }

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
      return
    }

    if (Object.keys(snackbar).length > 0) {
      showFn();

      timeout = setTimeout(() => {
        hideFn();
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [snackbar]);

  const { content, type } = snackbar;

  const child = (
    <>
      <GlobalSnackbarStyle />
      <Container
        isVisible={isVisible}
        type={type}
        onClick={hideFn}
      >
        <Content>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Content>
      </Container>
    </>
  );

  return (
    isMounted && ReactDOM.createPortal(
      child,
      portal,
    )
  )
});

export default Snackbar;
