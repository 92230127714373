import React, { useState, useEffect, useCallback } from 'react';
import { useSetState } from '@utils/hooks';
import { AutoSizer, InfiniteLoader } from 'react-virtualized';
import RowRnderer from './Row';
import { Container, Header, ListStyled } from './styles';

const STATUS_LOADING = 1;
const STATUS_LOADED = 2;
const ROW_HEIGHT = 64;

const ListComponent = ({
  dimensions,
  fetchData,
  columns,
  onRowClick,
  contentEntrySettings,
  environments,
  locales,
  gridHeaderItems = [],
  gridTemplateColumns
}) => {
  const [state, setState] = useSetState({ total: 0, list: [] });

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchData();
      const { total, list } = data;

      setState(state => {
        if (total) {
          state.total = total;
        }
        state.list = [...state.list, ...list];
      });
    }

    loadData();
  }, []);

  const [loadedRowsMap, setLoadedRowsMap] = useState({});
  const isRowLoaded = useCallback(
    ({ index }) => {
      return !!loadedRowsMap[index];
    },
    [loadedRowsMap]
  );

  const rowRenderer = useCallback(({ index, className, id, key, style }) => {
    const row = state.list[index];

    return (
      <RowRnderer
        key={key}
        id={id}
        className={className}
        style={style}
        row={row}
        index={index}
        contentEntrySettings={contentEntrySettings}
        environments={environments}
        locales={locales}
        columns={columns}
        loaded={loadedRowsMap[index] === STATUS_LOADED}
        onClick={() => onRowClick(row)} />
    );
  }, [state.list, loadedRowsMap]);

  const loadMoreRows = useCallback(({ startIndex, stopIndex }) => {
    const afterDate = state.list[state.list.length - 1].createdAt;

    for (let i = startIndex; i <= stopIndex; i++) {
      loadedRowsMap[i] = STATUS_LOADING;
    }

    setLoadedRowsMap(loadedRowsMap);

    return fetchData({ afterDate }).then(({ total, list }) => {
      for (let i = startIndex; i <= stopIndex; i++) {
        loadedRowsMap[i] = STATUS_LOADED;
      }

      setState({
        list: [...state.list, ...list]
      });

      setLoadedRowsMap(loadedRowsMap);
    });
  }, [state.list]);

  return (
    <Container gridTemplateColumns={gridTemplateColumns} style={{ paddingTop: (gridHeaderItems.length > 0 ? 30 : 0) }}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={state.total}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width, height }) => (
              <>
                {
                  gridHeaderItems.length > 0 && (
                    <Header style={{ width: width - 36 }}>
                      <div className="grid-row">
                        {
                          gridHeaderItems.map((item, i) => {
                            return (
                              <div key={`${item}-${i}`}>
                                <div>{item}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Header>
                  )
                }
                <ListStyled
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  rowCount={state.total}
                  height={dimensions.height - (gridHeaderItems.length > 0 ? 30 : 0)}
                  rowHeight={ROW_HEIGHT}
                  rowRenderer={rowRenderer}
                  width={width}
                />
              </>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Container>
  );
}

export default ListComponent;
