import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 390px;
  height: calc(100vh - 64px);
  padding: 30px;
  border-left: 1px solid var(--color-cs-fill-grey-4);
  overflow-y: scroll;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #EAEDF3;
  box-shadow: 0 1px 3px 0 rgba(0,0,0, 0.04);
  background-color: #fff;
  transform: none !important;

  ${({ isCloned }) => isCloned && css`
    & ~ div {
      transform: none !important;
    }
  `};

  > svg {
    margin-right: 10px;
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  .desc {
    font-size: 12px;
    line-height: 16px;
    color: #1d2230;
    font-weight: 400;
    opacity: .6;
  }
`;
