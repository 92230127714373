import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withSuspense } from '@utils/hooks';
import Sidebar from '@components/Sidebar';
const EditorToolbar = lazy(() => import('./page/editor/components/toolbar'));
const ListToolbar = lazy(() => import('./page/list/toolbar'));
import { list, editor } from './page';

export default {
  toolbar: [
    <Route
      key='toolbar'
      render={({ match: { url } }) => {
        return (
          <Switch>
            <Route exact path={`${url}/:type(entries)`} component={withSuspense(ListToolbar)} />
            <Route exact path={`${url}/:type(entries)/:objectId`} component={withSuspense(EditorToolbar)} />
          </Switch>
        )
      }}
    />
  ],

  sidebar: [
    <Route
      key='sidebar'
      render={({ match: { url } }) => {
        return (
          <Switch>
            <Route path={`${url}/:type(entries)`} component={() => <Sidebar defaultMenu />} />
          </Switch>
        )
      }}
    />
  ],

  routes: [
    <Route
      key='routes'
      render={({ match: { url } }) => {
        return (
          <Switch>
            <Route exact path={`${url}/:type(entries)`} component={list} />
            <Route exact path={`${url}/:type(entries)/:objectId`} component={editor} />
          </Switch>
        )
      }}
    />
  ],
};
