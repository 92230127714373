import request from '@api/request';
import immer from 'immer';
import storage from 'local-storage-fallback';
import config from "@config";
import { removeKeys, removeGlobalFieldsSubItems } from '@utils/helpers';
import { transformTypeModel } from './helpers';

const { APP_ID, JS_KEY, baseURL } = config;

const instance = request.create({ baseURL: `${baseURL}/content-api` });
instance.defaults.headers.common['X-Parse-Application-Id'] = APP_ID;
instance.defaults.headers.common['X-Parse-JavaScript-Key'] = JS_KEY;

instance.interceptors.request.use((c) => {
  let originalRequest = c;
  const cs = JSON.parse(storage.getItem('contentship'));
  if (cs && cs.token) {
    originalRequest.headers['ContentShip-Token'] = cs.token;
  }

  return originalRequest;
}, (err) => {
  return Promise.reject(err);
});

const API = {
  auth: async ({ username, password }) => {
    return instance.post('/auth', {
      username,
      password,
    }).then((r) => {
      const user = r.data;
      user['config'] = {
        theme: {
          sidebarExpanded: true
        }
      };

      storage.setItem('contentship', JSON.stringify(user));
      return r.data;
    });
  },

  getEnvironments: async () => {
    return instance.get(`/environment`)
      .then(({ data }) => {
        return data;
      });
  },

  getLocales: async () => {
    return instance.get(`/locale`)
      .then(({ data }) => {
        return data;
      });
  },

  findContent: async (params) => {
    return instance.get(`/find/content`, {
      params
    }).then(({ data }) => {
      return data;
    });
  },

  getModels: async (params) => {
    return instance.get(`/model`, {
      params
    }).then(({ data }) => {
      const { count, results } = data;
      const payload = {
        total: count
      }

      payload.list = results.map(item => {
        return transformTypeModel(item);
      });

      return payload;
    });
  },

  getModel: async ({ objectId } = {}) => {
    if (!objectId) {
      return Promise.resolve({
        error: {
          code: 1001,
          message: 'missing objectId'
        }
      });
    }

    return instance.get(`/model/${objectId}`)
      .then(({ data }) => {
        const payload = transformTypeModel(data);
        return payload;
      });
  },

  saveModel: async (data) => {
    const payload = immer(data, draftState => {
      removeKeys(draftState);
      removeGlobalFieldsSubItems(draftState);
    });

    return instance.post('/model', {
      payload,
    }).then(({ data }) => {
      const payload = transformTypeModel(data);
      return payload;
    });
  },

  getContents: async (params) => {
    return instance.get(`/content`, {
      params
    }).then(({ data }) => {
      const { count, results } = data;
      const payload = {
        total: count
      }

      payload.list = results.map(item => {
        return transformTypeModel(item);
      });


      return payload;
    });
  },

  getContent: async (params) => {
    if (!params.objectId) {
      return Promise.reject({
        error: {
          code: 1001,
          message: 'missing objectId'
        }
      });
    }

    return instance.get(`/content/${params.objectId}`, {
      params
    }).then(({ data }) => {
      const payload = transformTypeModel(data);
      return payload
    });
  },

  saveContent: async (data) => {
    const payload = immer(data, draftState => {
      removeKeys(draftState);
    });

    return instance.post('/content', {
      payload,
    }).then(({ data }) => {
      const payload = transformTypeModel(data);
      return payload
    });
  },

  publishContent: async (params) => {
    return instance.post('/publish', {
      params,
    }).then(({ data }) => {
      return data;
    });
  },
};

export default API;
