import { useEffect } from 'react'

const useBeforeUnload = (
  value,
) => {
  const handleBeforeunload = (evt) => {
    let returnValue;

    if (typeof value === 'function') {
      returnValue = value(evt)
    } else {
      returnValue = value;
    }

    if (returnValue) {
      evt.preventDefault();
      evt.returnValue = returnValue;
    }

    return returnValue;
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => window.removeEventListener('beforeunload', handleBeforeunload);
  }, [value]);
}

export default useBeforeUnload
