import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
`;

export const Holder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  pointer-events: none;
  z-index: 9998;

  ${({ showLoading, delay = '.4s' }) => !showLoading && css`
    animation: ${fadeOut} .4s ${delay} forwards;
  `}
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;
