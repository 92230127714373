import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
`;

export const EditorContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > textarea {
    flex: 1;
    min-height: 120px;
    max-height: 300px;
    margin-bottom: 10px;
    padding: 8px 14px;
    color: #3E3F42;
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #E8ECEF;
    resize: none;
    background: #fff;
  }

  .CodeMirror {
    flex: 1;
    /* width: 50%; */
    min-height: 80px;
    max-height: 300px;
    padding: 8px 14px;
    color: #3E3F42;
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #E8ECEF;
    background: #fff;
  }
`;

export const PreviewContainer = styled.div`
  flex: 1 1 50%;
  padding-left: 20px;
  min-height: 80px;
  max-height: 300px;
  white-space: pre-wrap;
  overflow: hidden;
  overflow-y: auto;

  /* Markdown Styles */
  p {
    display: block;
    margin: 1em 0 !important;
  }

  a:link,
  a:visited,
  a:active {
    text-decoration: none;
    color: #4078c0;
  }

  a:hover {
    text-decoration: underline;
  }

  blockquote {
    padding: 0 1em;
    color: #777;
    border-left: 0.25em solid #ddd;
    margin-left: 0 !important;
  }

  table {
    border-collapse: collapse;
  }

  table th,
  table td {
    border: 1px solid #ddd;
    padding: 6px 13px;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  li {
    padding: 2px;
  }

  img {
    max-width: 100%;
  }
`;
