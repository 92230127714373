import immer from 'immer';
import { unifyTypes } from '@utils/helpers';
import { contentSections } from '@utils/constants';

export const transformTypeModel = (payload) => {

  const typeModel = immer(payload, draftState => {
    unifyTypes(draftState);

    let sections = draftState.data || [];

    // append __meta to draft (data)
    draftState.__meta = {
      isNew: false,
      canSave: false,
    }

    // prepare section subItems
    draftState.data = sections.map((section, i) => {

      // append __meta to draft (section)
      const defaultSectionMeta = contentSections.find(t => t.type === section.type);

      section.__meta = {
        ...defaultSectionMeta.__meta,
        isNew: false,
        isOpen: true,
      }

      return section;
    });

    return draftState;
  });

  return {
    draft: typeModel,
    original: payload,
  }
}
