import React, { useRef } from 'react';
import { useSetState } from '@utils/hooks';
import MarkdownEditor from '@components/MarkdownEditor';
import { Container, Toolbar } from './styles';

const RichTextEditor = ({

}) => {

  return (
    <Container>
      <MarkdownEditor />
    </Container>
  );
};

export default RichTextEditor;
