import styled, { css } from 'styled-components';

export const Holder = styled.div`
`;

export const Container = styled.div`
  display: flex;
  overflow: hidden;
`;


export const Content = styled.div`
  flex: 1;
  height: calc(100vh - 64px);
  overflow: hidden;
  overflow-y: auto;
  position: static;


  .new {
    text-align: right;
    color: #555;
  }
`;


export const Section = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px 20px;

    &:first-child {
      padding-top: 20px;
    }
  `,
  Content: styled.div`
    min-height: 48px;
    max-height: 40px;
    padding: 20px 0;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;

    > div {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    ${({ isDragStart }) => css`
      /* padding-bottom: ${isDragStart ? '16px 0' : '0'}; */
      border: 1px ${isDragStart ? 'dashed #c3cbda' : 'solid #EAEDF3'};
      background-color: ${isDragStart ? 'rgba(33, 150, 243, 0.1)' : '#fff'};
      transition:
        background-color ${isDragStart ? '.4s 0s' : '.6s .4s'},
        border ${isDragStart ? '.4s 0s' : '.6s .4s'},
        min-height .4s cubic-bezier(0, 1, 0, 1) -.1s,
        max-height .4s cubic-bezier(0, 1, 0, 1) -.1s
      ;
    `}

    ${({ isSectionOpen }) => isSectionOpen && `
      max-height: 9999px;
      transition:
        background-color .4s,
        border .4s,
        min-height .4s 0s cubic-bezier(0.5, 0, 1, 0),
        max-height .4s 0s cubic-bezier(0.5, 0, 1, 0)
      ;
    `}

    ${({ isDragging }) => isDragging && `
      // border: 1px dotted #4D7CFE;
    `}

    ${({ isDraggingOver }) => isDraggingOver && `
      border: 1px dashed #c3cbda;
    `}
  `,
};
