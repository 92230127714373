import axios from 'axios';

axios.defaults.timeout = 10000;

axios.defaults.headers['Content-Type'] = 'application/json';

axios.defaults.validateStatus = status => (
  status >= 200 && status < 400
);

export default axios;
