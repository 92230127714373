import React from "react";
import Item from './components/items';
import { Section, Holder } from './styles';

const List = ({
  item,
}) => {
  const isSectionOpen = item.__meta.isOpen;

  return (
    <Section.Content isSectionOpen={isSectionOpen}>
      {
        item.subItems.map((subItem) => {
          return (
            <Holder key={subItem.id}>
              <Item
                item={subItem}
                isSectionOpen={isSectionOpen}
              />
            </Holder>
          )
        })
      }
    </Section.Content>
  );
};

export default List;
