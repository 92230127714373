import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 30px;
  height: 64px;
  background-color: var(--color-cs-bg);
`;

export const ContentHolder = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  padding: 6px 0 6px 36px;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
    left: 30px;
    border-radius: 4px;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px 0 rgba(0,0,0, 0.04);
    background-color: #fff;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
`;
