import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  transition: width .2s;
  overflow: hidden;

  ${({ expand }) => expand && `
    a {
      justify-content: flex-start !important;
      padding: 0 18px;

      svg {
        margin-right: 14px !important;
      }
    }
  `}

  a {
    justify-content: center;

    svg {
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

export const Button = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 64px;
  color: var(--color-cs-fill-grey-3);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  border-left: 2px solid transparent;

  &:hover {
    background-color: var(--color-cs-fill-grey-1);
  }

  &.active {
    border-color: #4D7CFE;
    background-color: var(--color-cs-fill-grey-1);

    svg {
      fill: #4D7CFE;
    }
  }

  svg {
    fill: var(--color-cs-fill-grey-3);
    margin-right: 14px;
  }
`;
