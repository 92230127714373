import React from 'react';
import { useInput } from '@utils/hooks';
import Switch from '@components/Switch';
import Dropdown from '@components/Dropdown';

const SectionSidebar = ({
  item,
  isGlobalField,
  handleItemApiIdChange,
  handleItemLabelChange,
  handleItemSettingsChange,
  contentGlobalFields,
  handleGlobalFieldChange,
}) => {
  const {
    data,
    isNew,
    isModified,
    hasErrors, // fixme: display the correct error for each field if any
  } = item;

  const renderItemLabel = () => {
    const [field, fieldInput] = useInput({
      component: 'input',
      label: 'field name',
      type: "text",
      value: isNew ? '' : data.label,
      isRequired: true,
      isValid: !isModified || data.label !== '',
      validationErrorMessage: 'cannot be empty',
      autoFocus: true,
      onChange: (value) => {
        handleItemLabelChange(value)
      }
    });

    return (
      <div key={data.id}>
        {fieldInput}
      </div>
    )
  }

  const renderItemApiId = () => {
    const [field, fieldInput] = useInput({
      label: 'api id',
      type: "text",
      value: isNew ? '' : data.apiId,
      isRequired: true,
      isValid: !hasErrors && (!isModified || data.apiId !== ''),
      validationErrorMessage: hasErrors ? 'Same Api ID already exists' : 'cannot be empty',
      onChange: (value) => {
        handleItemApiIdChange(value)
      }
    });

    return (
      <div key={data.appId}>
        {fieldInput}
      </div>
    )
  }

  const renderFields = () => {
    const { __meta } = data;
    let content = [...data.settings].map((field, fieldIndex) => {
      const isRequired = __meta.required.includes(field.label);

      const validate = () => {
        if (!isModified) {
          return true
        } else {
          if (isRequired) {
            return field.label !== '';
          } else {
            return true
          }
        }
      }

      if (['placeholder', 'validationRegex', 'minmax'].includes(field.type)) {
        const [fieldText, fieldInput] = useInput({
          label: field.label,
          type: "text",
          isRequired,
          isValid: validate(),
          validationErrorMessage: 'cannot be empty',
          value: field.value || '',
          onChange: (value) => {
            handleItemSettingsChange(fieldIndex, field.label, value);
          }
        });

        return (
          <div key={`field-${data.id}-${fieldIndex}`}>
            {fieldInput}
          </div>
        )
      }

      if (['required', 'repeatable'].includes(field.type)) {
        if (isGlobalField) return null;
        return (
          <Switch
            key={`field-${data.id}-${fieldIndex}`}
            label={field.label}
            checked={field.value}
            onClick={(value) => handleItemSettingsChange(fieldIndex, field.label, value)}
          />
        )
      }

      return null
    });

    return content;
  }

  const renderGlobalFieldsSettings = () => {
    let content;

    if (item.data.type === 'globalField') {
      const options = contentGlobalFields.list.map((o) => {
        return {
          key: o.draft.objectId,
          value: o.draft.name,
        }
      });
      return (
        <Dropdown
          label={'global fields'}
          options={options}
          selected={item.data._id}
          onChange={handleGlobalFieldChange} />
      )
    }

    return content;
  }

  return (
    <div>
      {renderItemLabel()}
      {renderItemApiId()}
      {renderFields()}<br />
      {renderGlobalFieldsSettings()}
    </div>
  );
};

export default SectionSidebar;
